<template>
  <div style='position: relative;'>
    <div class='button-group' v-if='!isShown'>
      <a href='#' @click.prevent.stop='open' class='button button--secondary'><i class='fas fa-eye'></i>Reveal</a>
      <a href='#' v-if='!isCopied' @click.prevent.stop='copyWithoutView($event)' class='button button--secondary'><i class='fas fa-copy'></i>Copy</a>
      <a href='#' v-if='isCopied' @click.prevent.stop='copyWithoutView($event)' class='button button--secondary'>Copied!</a>
    </div>
    <div class='otp' v-if='isShown && active'>
      <span v-if='!error' class='otp__value'>{{otp}}</span>
      <span v-if='error' class='otp__value danger'>ERROR</span>
      <div v-if='!error' class='progress' :style='{"width": `${countPercentage}%`}'></div>
      <nav v-if='!error'>
        <div style='display: inline-block'>
          <a href='#' v-if='!isCopied' @click.prevent.stop='copy($event)' class='button button--secondary'><i class='fas fa-copy'></i>Copy</a>
          <a href='#' v-if='isCopied' @click.prevent.stop='copy($event)' class='button button--secondary'>Copied!</a>
        </div>
      </nav>
    </div>
    <div class='otp' v-if='isShown && !active && !error'>
      <p class='otp__expired'>OTP expired</p>
      <nav>
        <div style='display: inline-block'>
          <a href='#' @click.prevent.stop='open' class='button button--secondary'><i class='fas fa-redo'></i>Refresh</a>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>

import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";

export default {
  props: ["url"],
  methods: {
    resetOtp () {
      var that = this;
      that.isShown = false;
      that.active = false;
      that.otp = "";
      that.isCopied = false;
      that.count = 30;
      that.error = false;
    },
    open () {
      var that = this;
      that.isShown = true;
      that.active = true;
      that.lookup()

      var countdown = setInterval(function() {
        that.count--;
        if (that.count <= 0) {
          clearInterval(countdown);
          that.resetOtp()
        }
      }, 1000);

      that.flash("Revealed!", 'success', 
        {
          timeout: 3000,
          important: true
        }
      );
    },
    lookup () {
      var that = this;

      that.$api.get(that.url)
      .then(function (response) {
        console.log(response.data)
        if (response.data["otp"]) {
          that.count = response.data["time_left"]
          that.otp = response.data["otp"];
        } else {
          that.error = true;
        }
      }).catch(function (error) {
        that.error = true;
      })
    },
    copy (e) {
      var that = this;
      that.setClipboard(that.otp, e)
    },
    copyWithoutView (e) {
      var that = this;
      

      var xhttp = new XMLHttpRequest();
      xhttp.open("GET", that.url, false);
      xhttp.send();
      var response = JSON.parse(xhttp.responseText);

      that.setClipboard(response["otp"], e)
      that.otp = response["otp"]
      that.count = response["time_left"]
    },
    setClipboard(value, e) {

      var that = this;

      ClipboardFunctions.copyText(value);
      that.isCopied = true;
      
      setTimeout(function(){ that.isCopied = false; }, 2000);

      that.flash(`Copied OTP - ${that.count} second(s) left!`, 'success', 
        {
          timeout: 3000,
          important: true
        }
      );
    }
  },
  computed: {
    countPercentage () {
      return ((1 - (this.count / 30)) * 100)
    }
  },
  data () {
    return {
      active: false,
      isShown: false,
      option: 6,
      otp: "",
      isCopied: false,
      count: 30,
      error: false
    }
  }
}

window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard() {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        createTextArea(text);
        selectText();
        copyToClipboard();
    };

    return {
        copy: copy
    };
})(window, document, navigator);
</script>

