<template>
  <div class='quick-adder'>
    <a href='#' class='quick-adder__trigger' v-if='!isShown' @click.prevent.stop="isShown = true"><i class='fas fa-plus'></i>Quick Add</a>
    <a href='#' class='quick-adder__trigger' v-if='isShown' @click.prevent.stop="isShown = true"><i class='fas fa-times'></i>Cancel</a>
    <div class='quick-adder__drop' v-if='isShown'>
      <header>
        <h3>Add To...</h3>
        
      </header>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: [""],
  mounted () {
    
  },
  data () {
    return {
      isShown: true
    }
  }
}
</script>

