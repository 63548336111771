<template>
  <div class='finder' @keydown.esc="showFinder = false">
    <a data-tooltip="Search" @click.prevent.stop="toggleFinder" class="tooltip-right header__a header__a--monitoring" href='#'><i class='fas fa-search'></i></a>

    <div class='finder__box' v-show='showFinder'>
      <div class='finder__close'>
        <a href='#' class='button button--secondary' @click.prevent.stop='toggleFinder'>Close search</a>
      </div>
      <div class='search' >
        <icon></icon>
        <input ref="searchInput" type='text'
          @click="showSearch = true"
          v-debounce:200ms="onInput"
          @keydown.down="onArrowDown"
          @keydown.up="onArrowUp"
          @keydown.enter="onEnter" v-model='q' placeholder="Search..." >
        <div class='search__box' v-show='showSearch'>
          <header class='search__header'>
            <label v-if='company'><input @change.prevent.stop="onInput" v-model='only_company' type='checkbox'>Search only in company</label>
            <label><input @change.prevent.stop="onInput" v-model='include_archived' type='checkbox'>Include archived</label>
          </header>
          <ul v-if='results.length > 0'>
            <li class='search__box__li__search' v-for='(result,i) in results' :key="i">
              <a :class="{ 'is-active': i === arrowCounter }" :href='result.url + "?q=" + q' :ref="'a' + i">
                <h2>{{result.name}}</h2>
                <span>{{ result.object_type }}</span>
              </a>
            </li>
          </ul>
          <div class='empty' v-if='results.length == 0'>
            <p>No Results Found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import Icon from "../components/icon.vue";

export default {
  name: 'search',
  props: ["company", "old_query", "forceClose"],
  computed: {
    active () {
      var count = 0;

      if (this.type != "")
        count++
      if (this.company != "")
        count++
      if (this.include_archived != false)
        count++
      if (this.asset_type != "")
        count++
      return count;
    }
  },
  mounted () {
    document.onkeydown = function(evt) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
          isEscape = (evt.key === "Escape" || evt.key === "Esc");
      } else {
          isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
        this.showFinder = false;
      }
    };
  },
  methods: {
    toggleFinder () {
      if (this.showFinder == false) {
        this.showFinder = true;
        this.$nextTick(() => this.$refs['searchInput'].focus())
      } else {
        this.showFinder = false;
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      if (this.arrowCounter != -1)
        this.$refs["a" + this.arrowCounter][0].click();
    },
    onInput () {

      var that = this;

      if (that.q.length > 0)
        that.showSearch = true

      if (that.q.toUpperCase() == "Chuck Norris".toUpperCase()) {
        var cNs = [
          "Chuck Norris can win a game of Connect Four in only three moves.",
          "Chuck Norris counted to infinity... Twice.",
          "When the Boogeyman goes to sleep at night he checks his closet for Chuck Norris.",
          "Chuck Norris can kill two stones with one bird.",
          "Once a cobra bit Chuck Norris' leg. After five days of excruciating pain, the cobra died.",
          "Chuck Norris will never have a heart attack... even a heart isnt foolish enough to attack Chuck Norris.",
          "Chuck Norris burst the dot com bubble.",
          "MySpace actually isn't your space, it's Chuck's (he just lets you use it).",
          "Chuck Norris can delete the Recycling Bin.",
          "Chuck Norris's keyboard doesn't have a Ctrl key because nothing controls Chuck Norris.",
          'When Chuck Norris is web surfing websites get the message "Warning: Internet Explorer has deemed this user to be malicious or dangerous. Proceed?."'
        ]
        var randomNumber = Math.floor(Math.random()*cNs.length);
        alert(cNs[randomNumber]);
      }

      if (that.q.toUpperCase() == "Godzilla".toUpperCase())
        alert("KING OF MONSTERS!! ROARRRRRR!!!!!!!!!!")

      if (that.q.toUpperCase() == "Derek Zoolander".toUpperCase())
        alert("But why male models?")

      if (that.q.toUpperCase() == "tell me a joke".toUpperCase())
        alert("Please ask Siri.")

      axios.get('/searches', {
        params: {
          q: that.q,
          company: that.only_company ? that.company : "",
          include_archived: that.include_archived
        }
      })
      .then(function (response) {
        that.results = response.data;
      })
    }
  },
  data: function () {
    return {
      showFinder: false,
      q: this.old_query || "",
      results: [],
      showFilters: false,
      arrowCounter: -1,
      showSearch: false,
      include_archived: false,
      only_company: this.company ? true : false 
    }
  },
  components: {
    Icon
  }
}
</script>

