<template>
  <div class='roler'>
    <input type='hidden' :value='security_level' name='user[security_level]'>
    <input type='hidden' :value='group_id' name='user[group_id]'>

    <table class='roler__table'>
      <thead>
        <tr>
          <th></th>
          <th :class='{"selected": security_level == "portal_member"}'>Portal Member</th>
          <th :class='{"selected": security_level == "spectator"}'>Spectator</th>
          <th :class='{"selected": security_level == "author"}'>Author</th>
          <th :class='{"selected": security_level == "editor"}'>Editor</th>
          <th :class='{"selected": security_level == "admin"}'>Admin</th>
          <th :class='{"selected": security_level == "super_admin"}'>Super Admin</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Add. Cost</th>
          <td :class='{"selected": security_level == "portal_member"}'>Free</td>
          <td :class='{"selected": security_level == "spectator"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "author"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "editor"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "admin"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "super_admin"}'><i class='fas fa-check-circle success'></i></td>
        </tr>
        <tr>
          <th>Max Number of Clients</th>
          <td :class='{"selected": security_level == "portal_member"}'>1</td>
          <td :class='{"selected": security_level == "spectator"}'><i class="fas fa-infinity"></i></td>
          <td :class='{"selected": security_level == "author"}'><i class="fas fa-infinity"></i></td>
          <td :class='{"selected": security_level == "editor"}'><i class="fas fa-infinity"></i></td>
          <td :class='{"selected": security_level == "admin"}'><i class="fas fa-infinity"></i></td>
          <td :class='{"selected": security_level == "super_admin"}'><i class="fas fa-infinity"></i></td>
        </tr>
        <tr>
          <th>Can Be In Group</th>
          <td :class='{"selected": security_level == "portal_member"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "spectator"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "author"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "editor"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "admin"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "super_admin"}'><i class='fas fa-times-circle danger'></i></td>
        </tr>
        <tr>
          <th>Read</th>
          <td :class='{"selected": security_level == "portal_member"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "spectator"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "author"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "editor"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "admin"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "super_admin"}'><i class='fas fa-check-circle success'></i></td>
        </tr>
        <tr>
          <th>Write</th>
          <td :class='{"selected": security_level == "portal_member"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "spectator"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "author"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "editor"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "admin"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "super_admin"}'><i class='fas fa-check-circle success'></i></td>
        </tr>
        <tr>
          <th>Archive</th>
          <td :class='{"selected": security_level == "portal_member"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "spectator"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "author"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "editor"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "admin"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "super_admin"}'><i class='fas fa-check-circle success'></i></td>
        </tr>
        <tr>
          <th>Delete</th>
          <td :class='{"selected": security_level == "portal_member"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "spectator"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "author"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "editor"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "admin"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "super_admin"}'><i class='fas fa-check-circle success'></i></td>
        </tr>
        <tr>
          <th>Flag</th>
          <td :class='{"selected": security_level == "portal_member"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "spectator"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "author"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "editor"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "admin"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "super_admin"}'><i class='fas fa-check-circle success'></i></td>
        </tr>
        <tr>
          <th>Access Documentation Tools</th>
          <td :class='{"selected": security_level == "portal_member"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "spectator"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "author"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "editor"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "admin"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "super_admin"}'><i class='fas fa-check-circle success'></i></td>
        </tr>
        <tr>
          <th>Access Admin</th>
          <td :class='{"selected": security_level == "portal_member"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "spectator"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "author"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "editor"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "admin"}'><i class='fas fa-check-circle success'></i></td>
          <td :class='{"selected": security_level == "super_admin"}'><i class='fas fa-check-circle success'></i></td>
        </tr>
        <tr>
          <th>Account Export</th>
          <td :class='{"selected": security_level == "portal_member"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "spectator"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "author"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "editor"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "admin"}'><i class='fas fa-times-circle danger'></i></td>
          <td :class='{"selected": security_level == "super_admin"}'><i class='fas fa-check-circle success'></i></td>
        </tr>
      </tbody>
    </table>

    <div v-if='uneditable'>
      <div class='form-group'>
        <p class='roler__uneditable'>This user's role can not be edited as it is the first super admin.</p>
      </div>
    </div>

    <div v-if='!uneditable'>
      <div class='alert' v-show='exceeded_or_met_license_count'><i class='fas fa-exclamation-triangle icon'></i>You have no more licensed users available. You can only add portal members.</div>
      <div class='form-group'>

        <label class='required'>Role</label>
        <div class='roler__choices'>
        <label :class='{"selected": security_level == "portal_member"}'>
          <input value='portal_member' v-model='security_level' type='radio'>
          <h2>Portal Member</h2>
        </label>
        <label :class='{"selected": security_level == "spectator", "disabled": exceeded_or_met_license_count}'>
          <div class="ribbon ribbon-top-right"></div>
          <input value='spectator' v-model='security_level' :disabled="exceeded_or_met_license_count" type='radio'>
          <h2>Spectator</h2>
        </label>
        <label :class='{"selected": security_level == "author", "disabled": exceeded_or_met_license_count }'>
          <div class="ribbon ribbon-top-right"></div>
          <input value='author' v-model='security_level' :disabled="exceeded_or_met_license_count" type='radio'>
          <h2>Author</h2>
        </label>
        <label :class='{"selected": security_level == "editor", "disabled": exceeded_or_met_license_count}'>
          <div class="ribbon ribbon-top-right"></div>
          <input value='editor' v-model='security_level' :disabled="exceeded_or_met_license_count" type='radio'>
          <h2>Editor</h2>
        </label>
        <label :class='{"selected": security_level == "admin", "disabled": exceeded_or_met_license_count}'>
          <div class="ribbon ribbon-top-right"></div>
          <input value='admin' v-model='security_level' :disabled="exceeded_or_met_license_count" type='radio'>
          <h2>Admin</h2>
        </label>
        <label :class='{"selected": security_level == "super_admin", "disabled": exceeded_or_met_license_count}' v-if='add_super_admin'>
          <div class="ribbon ribbon-top-right"></div>
          <input value='super_admin' v-model='security_level' :disabled="exceeded_or_met_license_count" type='radio'>
          <h2>Super Admin</h2>
        </label>
        </div>
      </div>

      <company-grabber v-if='security_level == "portal_member"' :existing='c' name='user[company_id]'></company-grabber>
      <div class='alert' v-show='security_level == "portal_member"'><i class='fas fa-exclamation-triangle icon'></i>The portal must be created AND activated, or the user will see a Not Found page.</div>
      <div class='alert' v-show='security_level == "portal_member" || security_level == "admin" || security_level == "super_admin" '><i class='fas fa-exclamation-triangle icon'></i>Users with this role can not be added to groups!</div>
      <div class='form-group' v-if='(groups.length > 0)' v-show='security_level != "portal_member" && security_level != "admin" &&security_level != "super_admin"'>
        <label>Group</label>
        <select v-model='group_id'>
          <option></option>
          <option :value='group.id' v-for='group in groups'>{{group.name}}</option>
        </select>
      </div>

    </div>
  </div>
</template>

<script>
import CompanyGrabber from './company_grabber.vue';
const axios = require('axios');
export default {
  props: ["uneditable", "add_super_admin", "exceeded_or_met_license_count", "presecurity_level", "pregroup_id", "groups", "precompany_id","precompany_name"],
  methods: {
  },
  components: {
    CompanyGrabber
  },
  data: function () {
    return {
      c: {id: this.precompany_id, name: this.precompany_name},
      security_level: this.presecurity_level || (this.exceeded_or_met_license_count ? "portal_member" : "spectator"),
      group_id: this.pregroup_id
    }
  }
}
</script>
