<template>
  <div class='mfilter'>
    <slot></slot>
    <div class='mfilter__dropdown'>
      <span>Sort:</span>
      <select @change='changeSort' v-model='sortBy'>
        <option value='name_asc'>Name ↑</option>
        <option value='name_desc'>Name ↓</option>
        <option value='oldest'>Oldest</option>
        <option value='newest'>Newest</option>
        <option value='last_updated'>Last Updated</option>
      </select>
    </div>
    <div class='mfilter__dropdown'>
      <span>Per Page:</span>
      <select @change='changePerPage' v-model='perPage'>
        <option value='10'>10 results</option>
        <option value='20'>20 results</option>
        <option value='30'>30 results</option>
        <option value='100'>100 results</option>
        <option value='200'>200 results</option>
        <option value='500'>500 results</option>
      </select>
    </div>
    <a class='mfilter__clear' v-show='showClear' title='Clear Filters' href='#' @click.prevent.stop='clearFilter'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M3.853 22.87C10.47 8.904 24.54 0 40 0H472C487.5 0 501.5 8.904 508.1 22.87C514.8 36.84 512.7 53.37 502.1 65.33L396.4 195.6C316.2 212.1 255.1 283 255.1 368C255.1 395.4 262.3 421.4 273.5 444.5C271.8 443.7 270.3 442.7 268.8 441.6L204.8 393.6C196.7 387.6 192 378.1 192 368V288.9L9.042 65.33C-.745 53.37-2.765 36.84 3.854 22.87H3.853zM287.1 368C287.1 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368C576 447.5 511.5 512 432 512C352.5 512 287.1 447.5 287.1 368zM491.3 331.3C497.6 325.1 497.6 314.9 491.3 308.7C485.1 302.4 474.9 302.4 468.7 308.7L432 345.4L395.3 308.7C389.1 302.4 378.9 302.4 372.7 308.7C366.4 314.9 366.4 325.1 372.7 331.3L409.4 368L372.7 404.7C366.4 410.9 366.4 421.1 372.7 427.3C378.9 433.6 389.1 433.6 395.3 427.3L432 390.6L468.7 427.3C474.9 433.6 485.1 433.6 491.3 427.3C497.6 421.1 497.6 410.9 491.3 404.7L454.6 368L491.3 331.3z"/></svg>
    </a>
  </div>
  
</template>

<script>
export default {
  props: ["presort", "preperpage"],
  methods: {
    changePerPage() {
      var url = new URL(window.location.href);
      url.searchParams.set('perPage', this.perPage);
      url.searchParams.delete('page');
      window.location = url
    },
    changeSort() {
      var url = new URL(window.location.href);
      url.searchParams.set('sortBy', this.sortBy);
      url.searchParams.delete('page');
      window.location = url
    },
    clearFilter () {
      var url = new URL(window.location.href);

      url.searchParams.delete('sortBy');
      url.searchParams.delete('query');
      url.searchParams.delete('perPage');
      url.searchParams.delete('page');
      window.location = url
    }
  },
  mounted () {
    
  },
  computed: {
    showClear () {
      var url = new URL(window.location.href)
      return ( url.searchParams.get("sortBy") || url.searchParams.get("query") || url.searchParams.get("perPage") )
    }
  },
  data() {
    return {
      sortBy: this.presort ? this.presort : "name_asc",
      perPage: this.preperpage ? this.preperpage : 20
    }
  }
}
</script>