<template>
  <div>
    <a href='#' class='button button--secondary button--large' @click.prevent.stop='isShown = true'><i class='fas fa-tools'></i>Bulk Manage</a>
    <div class='manage_modal__wrapper' v-if='isShown'>
      <flash-message></flash-message>
      <div class='manage_modal' v-click-outside="onClickOutside">
        <header>
          <h1><i class='fas fa-tools icon'></i>Bulk Manage</h1>
          <a href='#' class='button button--secondary' @click.prevent.stop='isShown = false'>Cancel</a>
        </header>

        <div class='bulk-manage__header'>
          <h2>{{selected_num}} selected</h2>
          <div class='button-group' v-if='!showMove'>
            <a href='#' @click.prevent.stop='archiveRecords' v-if='selected_num > 0' class='button--secondary button'>Archive</a>
          </div>
        </div>
        <div style='margin: 1rem auto;' v-if='loading'><loader></loader></div>
        <table class='bulk-manage' v-if='!loading'>
          <thead>
            <tr>
              <th><input type='checkbox' v-model="selectAll"></th></th>
              <th class='bulk-manage__td-name'>{{name}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='record in records'>
              <td><input type='checkbox' v-model="ids" :value="record.id"></th></td>
              <td class='bulk-manage__td-name'>{{record.name}}<span v-if='record.company_type'>{{record.company_type}}</span></td>
              <td>
                <div class='button-group'>
                  <a :href='record.url' target='_blank' class='button button--secondary'><i class='fas fa-external-link-alt'></i>Open in New Tab</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import loader from './loader.vue';
import CompanyGrabber from './company_grabber.vue'
import vClickOutside from 'v-click-outside'

export default {
  props: ["name", "url"],
  components: {
    loader,
    CompanyGrabber
  },
  mounted () {
    var that = this;

    that.$api.get(that.url)
    .then(function(response) {
      that.records = response.data
    }).catch(error => {
      alert(error.response.data)
    })
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside (event) {
      this.isShown = false
    },
    companyAdded (value) {
      this.moveCompanyId = value.id
    },
    addSelected(id) {
      this.ids.push(id)
    },
    archiveRecords () {
      var that = this;

      that.loading = true;
      if(confirm(`Are you sure you want to archive ${this.ids.length} records ?`)){
        that.$api.post('/archive_records.json', 
          {
            "record_type": "Company",
            "records": that.ids
          },
          { 
            headers: {'Content-Type': 'application/json'}
          }).then(function (response) {

          that.ids.forEach(function (selected_value) {

            that.records.splice(that.records.findIndex(function(i){
                return i.id === selected_value;
            }), 1);

          })

          that.ids = []
          that.loading = false
          that.flash(response.data.msg, 'success', 
            {
              timeout: 3000,
              important: true
            }
          );
        }).catch(error => {

          that.flash("Something went wrong!", 'error', 
            {
              timeout: 3000,
              important: true
            }
          );
          console.log(error)
        })
      }
    },
  },
  computed: {
    selectAll: {
      get: function () {
          return this.records ? this.ids.length == this.records.length : false;
      },
      set: function (value) {
          var ids = [];

          if (value) {
              this.records.forEach(function (record) {
                  ids.push(record.id);
              });
          }

          this.ids = ids;
      }
    },
    selected_num () {
      return this.ids.length
    }
  },
  data () {
    return {
      records: [],
      loading: false,
      isShown: false,
      showMove: false,
      moveCompanyId: "",
      ids: []
    }
  }
}
</script>