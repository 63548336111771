<template>
  <div class='segment__header'>
    <h2>DNS</h2>
    <div class='dns__indicator'>
      <span v-if='num > 1'><i class="fas fa-exclamation-triangle icon"></i>{{num}} changes</span>
      <span v-if='num <= 1'>No changes</span>
      <a href='#' class='button button--secondary' @click.prevent.stop="isShown = !isShown">Historical DNS</a>
    </div>
    <transition name='popopen'>
      <div class='dns' v-if='isShown'>
        <a href='#' class='close' @click.prevent.stop="isShown = false"><i class='fas fa-times'></i></a>
        <slot></slot>
        
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["num"],
  mounted () {
    
  },
  data () {
    return {
      isShown: false
    }
  }
}
</script>

