<template>
  <div class=''>
    <a href='#' v-tooltip.left="isSplit ? 'Expand' : 'Collapse'" @click.prevent.stop='switchSplit' :class='{"toggle-pane-button cpanel__switch": true, "cpanel__switch--open": isSplit}'><i class="fas fa-angle-double-right"></i></a>
  </div>

</template>

<script>
export default {
  data: function () {
    return {
      isSplit: false
    }
  },
  mounted () {
    if (localStorage.getItem('huduAssetSidebarIsSplit')) {
      try {
        this.isSplit = JSON.parse(localStorage.getItem('huduAssetSidebarIsSplit'));
      } catch(e) {

      }
    }
  },
  watch: {
    isSplit: function (newIsSplit, oldIsSplit) {
      var columns = document.querySelector(".cpanel")
      var aside = document.querySelector(".cpanel__aside");
      if (newIsSplit == true) {
        columns.classList.add("cpanel--full")
        aside.classList.add("cpanel__aside--blank")
      } else {
        columns.classList.remove("cpanel--full")
        aside.classList.remove("cpanel__aside--blank")
      }
    }
  },
  methods: {
    switchSplit () {

      var columns = document.querySelector(".cpanel");
      var aside = document.querySelector(".cpanel__aside");
      var fullWidthClass = "cpanel";

      this.isSplit = !this.isSplit;
      localStorage.setItem('huduAssetSidebarIsSplit', this.isSplit)

      if (columns.classList.contains(fullWidthClass)) {
        columns.classList.add(fullWidthClass)
        aside.classList.add("cpanel__aside--blank")
      } else {
        columns.classList.remove(fullWidthClass)
        aside.classList.remove("cpanel__aside--blank")
      }
    }
  }
}
</script>

