var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{ref:"iptable",attrs:{"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"theme":_vm.dark_mode ? "nocturnal" : "default","styleClass":"vgt-table striped","select-options":{
  enabled: true,
  selectOnCheckboxOnly: true,
  selectionInfoClass: 'custom-select-row',
},"pagination-options":{
  enabled: true,
  mode: 'records',
  perPage: 50,
  position: 'bottom',
  perPageDropdown: [10, 20, 50, 100, 200],
  dropdownAllowAll: false,
  setCurrentPage: 1,
  nextLabel: 'next',
  prevLabel: 'prev',
  rowsPerPageLabel: 'Items per page',
  ofLabel: 'of',
  pageLabel: 'page', // for 'pages' mode
  allLabel: 'All'
},"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-selected-rows-change":_vm.selectionChanged,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'address')?_c('span',[_c('a',{attrs:{"href":props.row.url}},[_vm._v(_vm._s(props.row.address))])]):_vm._e(),_vm._v(" "),(props.column.field == 'assigned_to')?_c('span',[_c('a',{attrs:{"href":props.row.asset_url}},[_vm._v(_vm._s(props.row.asset_name))])]):_vm._e(),_vm._v(" "),(props.column.field != 'address' && props.column.field != 'assigned_to')?_c('span',[_vm._v("\n    "+_vm._s(props.formattedRow[props.column.field])+"\n  ")]):_vm._e()]}}])},[(_vm.can)?_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('div',{staticClass:"button-group"},[_c('a',{staticClass:"button button--danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteRecords.apply(null, arguments)}}},[_vm._v("Delete Selected")])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }