<template>
  <div class='legacier'>
    <div style='display: inline-block;'>
      <a href='#' v-if='!isShown' class='button button--secondary' @click.prevent.stop="isShown = !isShown">View Legacy Options</a>
      <a href='#' v-if='isShown' class='button button--secondary' @click.prevent.stop="isShown = !isShown">Hide Legacy Options</a>
    </div>
    <slot v-if='isShown'></slot>
  </div>
</template>

<script>
export default {
  props: [],
  data () {
    return {
      isShown: false
    }        
  },
  methods: {
  }
}
</script>