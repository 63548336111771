
<template>
  <div>
    <div class='mkb__navbar-header'>
      <a href='#' @click.prevent.stop='isNavigate = false' :class='{"selected": !isNavigate }'>Tools</a>
      <a href='#' @click.prevent.stop='isNavigate = true' :class='{"selected": isNavigate }'>Navigate KB</a>
    </div>
    <slot name='tools' v-if='!isNavigate'></slot>
    <slot name='navigate'  v-if='isNavigate'></slot>
  </div>
</template>

<script>


export default {
  props: [],
  data () {
    return {
      isNavigate: false
    }
  }
}
</script>

