<template>
  <div @keydown.esc="close" class='portal-password-viewer portal__card-item'>
    <a href='#' @click.prevent.stop='isShown = true'>
      <h2 v-if='label'>{{label}}</h2>
    </a>
    <a href='#' @click.prevent.stop='isShown = true' class='button button--secondary'><i class='fas fa-key'></i>{{portal_view_name}}</a>
    <div class='portal-password-viewer__modal' v-show='isShown'>
      <main>
        <header>
          <h1>{{name}}</h1>
          <a href='#' @click.prevent.stop='isShown = false' class='button button--secondary'>{{portal_close_name}}</a>
        </header>
        <slot></slot>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "portal_close_name", "portal_view_name", "label", "url", "portal_id", "record_id", "record_type"],
  mounted () {
    
  },
  data () {
    return {
      isShown: false,
    }
  },
  methods: {
    close () {
      this.isShown = false;
    },
  }
}
</script>

