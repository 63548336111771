<template>
  <transition name="fade">
    <div class='toast' v-if='isShown' :class="{'toast--alert': type == 'alert'}">
      {{ message }}
    </div>
  </transition>
</template>

<script>
const axios = require('axios');

export default {
  props: ["message", "type", "show"],
  mounted () {

    var that = this;
    
    that.isShown = true;
    setTimeout(function(){
      that.isShown = false
    }, 1500);
  },
  data: function () {
    return {
      isShown: true
    }
  }
}
</script>

