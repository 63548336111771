<template>
  <div>
    <img v-show='!error' @error="onLoadError" style='max-height: 65px; max-width: 100px;' :src='computedSrc' on></img>
    <div v-show='error' ref="logo_blank" class='company__logo__blank' style='display: none'>{{initials}}</div>
  </div>
</template>

<script>

export default {
  props: ["website", "initials"],
  data: function () {
    return {
      error: false
    }
  },
  computed: {
    computedSrc () {
      return `https://logo.clearbit.com/${this.website}?size=65`
    }
  },
  methods: {
    onLoadError () {
      this.error = true
    }
  },
  mounted () {
   
  }
}


</script>