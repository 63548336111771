<template>
  <div style="display: inline">
    <a class="asset-sidebar__group--toggle" @click.prevent.stop='toggle' href="#">
      <span class='asset-sidebar__group--toggle-icon'>
        <i :class='{"fas fa-chevron-down": true, "asset-sidebar__group__open-icon": !isShown}'></i>
      </span>
      <span class='asset-sidebar__group--toggle-text'><slot></slot></span>
    </a>
  </div>
</template>

<script>

export default {
  props: ["name"],
  mounted () {
    if (localStorage.getItem(`huduWidget${this.name}IsVisible`)) {
      try {
        this.isShown = JSON.parse(localStorage.getItem(`huduWidget${this.name}IsVisible`));
      } catch(e) {

      }
    }

    var content = document.querySelector(`.asset-sidebar__group-content--${this.name}`);
    var hideClass = "asset-sidebar__group-content--hidden";

    if (this.isShown == true) {
      // localStorage.setItem(`huduWidget${this.name}isVisible`, this.isShown)
      content.classList.remove(hideClass)
    } else {
      // localStorage.setItem(`huduWidget${this.name}isVisible`, this.isShown)
      content.classList.add(hideClass)
    }
  },
  methods: {
    toggle () {
      this.isShown = !this.isShown;
      var content = document.querySelector(`.asset-sidebar__group-content--${this.name}`);
      var hideClass = "asset-sidebar__group-content--hidden";


      if (content.classList.contains(hideClass)) {
        localStorage.setItem(`huduWidget${this.name}IsVisible`, this.isShown)
        content.classList.remove(hideClass)
      } else {
        localStorage.setItem(`huduWidget${this.name}IsVisible`, this.isShown)
        content.classList.add(hideClass)
      }
    }
  },
  data: function () {
    return {
      isShown: true
    }
  }
}
</script>

