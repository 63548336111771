<template>
  <div class='name-list'>
    <template v-if='!is_edit'>
      <template v-if='(options.length > 0) && !isShown'>

        <select id='asset_name' v-model='selected' required>
          <option v-for='o in options' :value='o'>{{o}}</option>
        </select>
        <input type='hidden' name='asset[name]' :value='selected'>
        <a href='#' v-if='!isShown' @click.prevent.stop='isShown = true' class='name-list__toggle'>Use a custom name</a>
      </template>
      <slot v-if='(options.length == 0) || isShown'></slot>
    </template>
    <template v-if='is_edit'>
      <slot v-if='(options.length == 0) || isShown'></slot>
    </template>
  </div>

</template>

<script>


export default {
  props: ["options", "is_edit"],
  data () {
    return {
      isShown: false,
      selected: this.options[0]
    }
  },
  methods: {
    makeEditable () {
      this.isEditableAgain = true;
      this.isShown = true;
    }
  },

}
</script>

