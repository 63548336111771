<template>
  <div class='modal-backdrop' v-if='isShown'>
    <div class='vault vault--small' v-click-outside="onClickOutside">
      <div class='vault__header'>
        <h1>Active Integrations</h1>
        <div class='button-group'>

          <a href='#' class='close' @click.prevent.stop="toggle(0)"><i class='fas fa-times'></i></a>
        </div>
      </div>
      <slot v-if='isShown'></slot>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { mapGetters } from 'vuex'

export default {
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters("globals", {
      'isShown': 'showSyncBar'
    })
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside (event) {
      if (this.isShown) {
        this.toggle(0);
      }
    },
    toggle(newHeader) {
      this.$store.commit('globals/changeSub', newHeader)
    },
  }
}
</script>

