<template>
  <div class='card__tag popopen' @keydown.esc="isShown = false" v-click-outside="onClickOutside">
    <a @click.prevent.stop='isShown = !isShown' href='#' class='card__tag__trigger'><i class='fas fa-info-circle'></i></a>
    <transition name='popopen'>
      <slot v-if='isShown'></slot>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { mapGetters } from 'vuex'

export default {
  props: ["name", "image"],
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      isShown: false
    }
  },
  methods: {
    onClickOutside (event) {
      this.isShown = false
    }
  },

}
</script>

