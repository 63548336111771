<template>
  <div>
    
  </div>
</template>

<script>

export default {
  props: [],
  mounted () {
    if (document.getElementById('email')) {
      document.getElementById('email').focus();
    }
    if (document.getElementById('otp')){
      document.getElementById('otp').focus();
    }
  }
}
</script>