<template>
  <div class='ringer' @keydown.esc="toggle(0)" v-click-outside="onClickOutside">
    <a href='#' @click.prevent.stop='switchToggle' :class='{"ringer__trigger": true, "ringer__trigger--active": count > 0}'>
      <i class='fas fa-bell'></i>
      <span v-if='count > 0'>{{count}}</span>
    </a>

    <transition name='popopen'>
      <div class='ringer__content' v-if='isShown'>
        <header>
          <h3>Upcoming...</h3>
          <a href='#' @click.prevent.stop='toggle(0)' class='button button--secondary button--small'>Close</a>
        </header>
        <slot>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { mapGetters } from 'vuex'

export default {
  props: ["count"],
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters("globals", {
      'isShown': 'showRinger'
    })
  },
  methods: {
    onClickOutside (event) {
      if (this.isShown) {
        this.toggle(0);
      }
    },
    toggle(newSub) {
      this.$store.commit('globals/changeSub', newSub)
    },
    switchToggle () {
      if (this.isShown) {
        this.toggle(0)
      } else {
        this.toggle(1)
      }
    }
  }
}
</script>

