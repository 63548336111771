


<template>

  <div>
    <a href='#' class='monitoring__button button button--small button--secondary' v-if='!isShown' @click.prevent='isShown = true'>View Information</a>
    <a href='#' class='monitoring__button button button--small button--secondary' v-if='isShown' @click.prevent='isShown = false'>Hide Information</a>
    <footer v-if='isShown'><slot></slot></footer>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  data: function () {
    return {
      isShown: false
    }
  }
}
</script>

