
<template>

</template>

<script>


export default {
  props: [],
  data () {
    return {

    }
  },
  mounted () {
    var all_users_input = document.querySelector("#password_folder_security_all_users")
    var specific_input = document.querySelector("#password_folder_security_specific")

    var form = document.querySelector(".form__password-folder-list");

    form.style.display = "none";


    if (specific_input.checked) {
      form.style.display = "block";
    } else {
      form.style.display = "none";
    }

    document.querySelectorAll("input[type=radio]").forEach(radio => {
      radio.addEventListener('change', () => {

        if (specific_input.checked) {
          form.style.display = "block";
        } else {
          form.style.display = "none";
        }
      })
    });
  }

}
</script>

