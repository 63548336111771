<template>
  <label class='porter__option'>
    <input type='checkbox' @input.prevent.stop='check'> Check all
  </label>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      isCheck: false
    }
  },
  methods: {
    check () {

      this.isCheck = !this.isCheck

      var boxes = document.querySelectorAll(".exporter-input-option")

      for(var i=0, n=boxes.length;i<n;i++) {
        boxes[i].checked = this.isCheck;
      }

    }
  },

}
</script>

