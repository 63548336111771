<template>
  <div class='layout-sorter'>
    <template v-for='(rule, index) in rules'>
      <input type='hidden' :name='"integrator[rules_attributes][" + index + "][id]"' v-model='rule.id'>
      <input type='hidden' :name='"integrator[rules_attributes][" + index + "][configuration_type_id]"' v-model='rule.configuration_type_id'>
      <input type='hidden' :name='"integrator[rules_attributes][" + index + "][asset_layout_id]"' v-model='rule.asset_layout_id'>
      <input type='hidden' :name='"integrator[rules_attributes][" + index + "][_destroy]"' v-model='rule._destroy'>
      <input type='hidden' :name='"integrator[rules_attributes][" + index + "][new_asset_layout_name]"' v-model='rule.new_asset_layout_name'>
    </template>
    <header>
      <slot></slot>
      <div class='sort-rules'>
        <section v-if='!rule._destroy' v-for='(rule, index) in rules'>
          <div>
            <h2>When Type Is</h2>
            <select v-model='rule.configuration_type_id'>
              <option v-for="rt in role_types" :value="rt.id">{{rt.name}}</option>
              }
            </select>
          </div>
          <div class='sort-rules__sync-to'>
            <i class="fas fa-arrow-right"></i>
          </div>
          <div class='sort-rules__hudu'>
            <h2>Then, Place In</h2>
            <select v-if='!newNameIndex.includes(index)' v-model='rule.asset_layout_id'>
              <option v-for="at in asset_types" :value="at.id">{{at.name}}</option>
            </select>
            <a href='#' class='sort-rules__add-trigger' v-if='!newNameIndex.includes(index)' @click.prevent.stop='toggleNewName(index)'>Or, create a new asset layout for this type</a>
            <div class='sort-rules__add' v-if='newNameIndex.includes(index)'>
              <input type='text' id="'newNameInput' + rule.id" v-model='rule.new_asset_layout_name' placeholder='New Asset Layout Name...'>
              <a href='#'  class='sort-rules__add-trigger' @click.prevent.stop='removeNewName(index)'>Use an existing asset layout</a>
            </div>
          </div>
          <a href='#' class='button button--danger' @click.prevent.stop='removeRule(index)'><i class='fas fa-trash'></i>Remove</a>
        </section>
        <div style='margin-top: 1rem;display: inline-block'>
          <a href='#' @click.prevent.stop='addRule' class='button button--success'>Add Sort Rule</a>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  props: ["prerules", "asset_types", "role_types"],
  data () {
    return {
      rules: [],
      isShown: false,
      newNameIndex: []
    }        
  },
  mounted () {
    this.rules = this.prerules.rules;
  },
  methods: {
    addRule () {
      var that = this;
      this.rules.push({
        id: null,
        asset_layout_id: that.asset_types[0]["id"],
        configuration_type_id: this.role_types[0]["id"],
        _destroy: false
      })
    },
    toggleNewName (index) {
      this.newNameIndex.push(index)
    },
    removeNewName (index) {
      this.newNameIndex = this.removeFromArray(this.newNameIndex, index);
      this.rules[index]["new_asset_layout_name"] = ""
    },
    removeFromArray(array, num) {
      let index = array.indexOf(num);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array
    },
    removeRule (index) {
      if(confirm("Are you sure?")){
        this.rules[index]._destroy = true;
      }
    }
  }
}
</script>