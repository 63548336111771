

<template>
  <div class='table-scroll'>
    <table>
      <thead>
        <tr>
          <th>Line #</th>
          <th v-for='field in meta["fields"]'>{{field}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(datum, index) in data'>
          <td>{{ index + 2 }}</td>
          <td v-for='field in meta["fields"]'>{{datum[field]}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    props: ["meta", "data"]
  }
</script>