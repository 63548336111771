<template>
  <div>
    <flash-message></flash-message>
    <div class="form-group">
      <div class="button-group">
        <a href="#" v-if='!enabled' @click.prevent.stop="toggleEnable" class="button button--primary">Enable IP Access Control</a>
        <a href="#" v-if='enabled' @click.prevent.stop="toggleEnable" class="button button--danger">Disable IP Access Control</a>
      </div>
    </div>
    <div class='alert alert--column'>
      <p>Turning on IP Access Control will <b>only</b> allow the following IP Addresses access to Hudu. These rules do not apply to publicly shared pages, the REST API, and Hudu external apps.</p>
      <p v-if='enabled && items.length == 0'>IP Access Control is not active until you add at least one IP address!</p></div>
    <div class="simple-crud">
      <div class='button-group'>
        <a href="#" class="button button--success" @click.prevent.stop="openAdd"><i class='fas fa-plus'></i>New IP Address</a>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Allowed IP Address(es)</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, index) in items">
            <td>{{i.ip_address}}</td>
            <td>{{i.description}}</td>
            <td class="simple-crud__actions">
              <a href='#' @click.prevent.stop="openEdit(i.id, i.ip_address, i.description)">Edit</a>
              <a href='#' @click.prevent.stop="deleteAddress(i.id, index)"><i class='fas danger fa-trash'></i></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Teleport to="body">
      <div class='manage_modal__wrapper' v-show='isShown'>
        <div class='manage_modal manage_modal--small'>
          <header>
            <h1 v-if="!formIsEdit">New IP Address</h1>
            <h1 v-if="formIsEdit">Edit IP Address</h1>
            <a href='#' class='button button--secondary' @click.prevent.stop='isShown = false'>Cancel</a>
          </header>
          <div class="manage__modal__body">
            <p style="line-height: 1.4;margin-top: 0;" v-if="!formIsEdit">Enter an IP address or a range of IP addresses to limit access. You can use CIDR notation. Accepts both IPv4 and IPv6.</p>
            <p style="line-height: 1.4;margin-top: 0;">Your IP Address is <b>{{current_ip}}</b></p>
            <form @submit.prevent.stop="createOrUpdateAddress">
              <ul class='alert--errors' v-if="error">
                <li><i class="fas fa-exclamation-triangle"></i>{{error}}</li>
              </ul>
              <div class="form-group">
                <label for="ip_address_ip_address" class="required">IP Address</label>
                <input type="text" v-model='ip_address' id="ip_address_ip_address" required>
              </div>
              <div class="form-group">
                <label for="ip_address_description" class="required">Description</label>
                <input type="text" v-model='description' id="ip_address_description" required>
              </div>
              <input v-if="!formIsEdit" type="submit" class="button button--primary" value="Create">
              <input v-if="formIsEdit" type="submit" class="button button--primary" value="Update">
            </form>
          </div>
        </div>
      </div>
    </Teleport>
  </div>

</template>

<script>
import Teleport from 'vue2-teleport';
import flash from "../lib/flashes.js";
export default {
  props: ["url", "current_ip", "toggle_url"],
  components: {
    Teleport
  },
  methods: {
    createOrUpdateAddress () {
      var that = this;

      if (that.formIsEdit) {
        that.$api.put(`${that.url}/${that.id}`, { ip_allow_address: {ip_address: that.ip_address, description: that.description}}).then(function (response) {
          fetch(that.url)
          .then((resp) => resp.json())
          .then(function(data) {
            that.items = data.addresses;
            that.enabled = data.enable_ip_access_control;
            that.clearEdit()
            flash('IP Address Updated!','success')
          })
        }).catch(error => {
          that.error = error.response.data.errors[0]
        })
      } else {
        that.$api.post(`${that.url}`, { ip_allow_address: {ip_address: that.ip_address, description: that.description}}).then(function (response) {
          fetch(that.url)
          .then((resp) => resp.json())
          .then(function(data) {
            that.items = data.addresses;
            that.enabled = data.enable_ip_access_control;
            that.clearEdit()
            flash('IP Address Created!','success')
          })
        }).catch(error => {
          that.error = error.response.data.errors[0]
        })
      }

    },
    toggleEnable () {
      var that = this;
      that.$api.put(that.toggle_url).then(function (response) {
        that.enabled = !that.enabled;
        if (that.enabled) {
          flash('IP Access Control Enabled','success')
        } else {
          flash('IP Access Control Disabled','success')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    clearEdit () {
      this.ip_address = "";
      this.id = "";
      this.description = "";
      this.isShown = false
      this.error = ""
    },
    openAdd () {
      this.clearEdit()
      this.formIsEdit = false;
      this.isShown = true
    },
    openEdit (id, ip_address, description) {
      this.clearEdit()
      this.id = id;
      this.formIsEdit = true;
      this.ip_address = ip_address;
      this.description = description;
      this.isShown = true
    },
    deleteAddress (id, index) {
      var that = this;

      if(confirm('Are you sure?')) {
        that.$api.delete(`${that.url}/${id}`).then(function (response) {
          that.items.splice(index, 1);
        }).catch(error => {
          console.log(error)
        })
      }
    },
  },
  mounted () {
    var that = this;

    fetch(that.url)
    .then((resp) => resp.json())
    .then(function(data) {
      that.items = data.addresses;
      that.enabled = data.enable_ip_access_control;
    })
  },
  data() {
    return {
      enabled: true,
      ip_address: "",
      error: "",
      id: "",
      formIsEdit: false,
      description: "",
      isShown: false,
      items: []
    }
  }
}
</script>
