
<template>

</template>

<script>


export default {
  props: [],
  data () {
    return {

    }
  },
  mounted () {
    var enableInput = document.querySelector("#account_enable_mobile_biometric_auth_true")

    var form = document.querySelector("#form__password_authentication");

    form.style.display = "none";


    if (enableInput.checked) {
      form.style.display = "block";
    } else {
      form.style.display = "none";
    }

    document.querySelectorAll("input[type=radio]").forEach(radio => {
      radio.addEventListener('change', () => {

        if (enableInput.checked) {
          form.style.display = "block";
        } else {
          form.style.display = "none";
        }
      })
    });
  }

}
</script>

