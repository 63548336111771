<template>
  <div class='linker'>

    <input type='hidden' name='asset_password[passwordable_id]' :value="id">
    <input type='hidden' name='asset_password[passwordable_type]' :value="type">

    <div class='field--link' v-if='id != ""'>
      <a>{{ name }}</a>
      <a href='#' class='field--link__remove' @click.prevent="remove"><i class='fas fa-times'></i></a>
    </div>

    <div class='search search--hitcher' v-if='id == ""' v-click-outside="onClickOutside">
      <icon></icon>
      <input type='text'
        @click="showSearch = true"
        @input="onInput"
        @keydown.esc="showSearch = false"
        v-model='q' placeholder='Search for parent or leave blank for no parent...'>
      <div class='search__box' v-show='showSearch'>
        <nav class='search__box__switcher'>
          <a href='#' @click.prevent.stop='switchType("Asset")' :class='{"selected": type != "Website"}'>Asset</a>
          <a href='#' @click.prevent.stop='switchType("Website")' :class='{"selected": type == "Website"}'>Website</a>
        </nav>
        <ul v-if='results.length > 0'>
          <li class='search__box__li__hitcher' v-for='(result,i) in results' :key="i">
            <h2>
              <a target="_blank" :href='result.url' class='button button--primary button--small'>View</a>
              <a href='#' @click.prevent="add(result.id, result.url, result.name)" class='button button--success button--small'>Choose</a>
              <a href='#' @click.prevent="add(result.id, result.url, result.name)">{{result.name}}<small class='search__small'>{{result.company_name}}</small></a>
            </h2> 
          </li>
        </ul>
        <div class='empty' v-if='results.length == 0'>
          <p >No results found</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../components/icon.vue";
import vClickOutside from 'v-click-outside'

export default {
  props: ["preid", "pretype", "prename", "preurl", "company"],
  mounted () {
    var that = this;
  },
  components: {
    Icon
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside(evt) {
      this.showSearch = false;
      this.arrowCounter = -1;
    },
    remove () {
      this.id = ""
      this.name = ""
      this.url = ""
    },
    switchType (type) {
      this.type = type;
      this.onInput()
    },
    add (id, url, name) {
      this.id = id
      this.name = name
      this.url = url
      this.showSearch = false;
    },
    onInput () {

      var that = this;

      if (that.q.length > 0) {
        that.showSearch = true;
      } else {
        that.showSearch = false;
      }

      that.$api.get('/searches', {
        params: {
          q: that.q,
          type: that.type,
          company: that.company,
          asset_type: that.linkable_id
        }
      })
      .then(function (response) {
        that.results = response.data;
      })
    }
  },
  data: function () {
    return {
      q: "",
      results: [],
      showSearch: false,
      id: this.preid,
      url: this.preurl,
      type: this.pretype || "Asset",
      name: this.prename
    }
  }
}
</script>

