<template>
  <div class='button-group'>
    <div class='button-group' v-if="showEdit">
      <a href='#' @click.prevent.stop='toggleEdit' class='button button--xlarge button--secondary'>Cancel Editing</a>
      <a href='#' @click.prevent.stop='submitForm' class='button button--xlarge button--primary'>Update Process</a>
    </div>
    <template v-if='can && !showEdit'>
      <template>
        <a href='#' @click.prevent.stop='toggleEdit' class='button button--icon button--secondary' v-tooltip="'Edit'"><i class='far fa-edit'></i></a>
        <slot></slot>
      </template>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters } from 'vuex'
import * as ClipboardFunctions from "../../lib/ClipboardFunctions.js";

export default {
  props: ["url", "can", "turn_off_completion"],
  computed: {
    ...mapState("procedures", {
      'showEdit':  'showEdit',
      'procedure': 'procedure',
      'showDescription': 'showDescription',
      'openProcessView': 'openProcessView',
      'openProcessEdit': 'openProcessEdit',
      'openCompletionEdit': 'openCompletionEdit',
    }),
     ...mapGetters("procedures", {
      'tasksLength':  'tasksLength',
      'procedureJSON': 'procedureJSON'
    }),
    procedure_tasks_attributes: {
      get() {
        return this.procedure.procedure_tasks_attributes
      },
      set(value) {
        this.$store.dispatch('procedures/update_tasks', value)
      }
    }
  },
  mounted () {
    //this.$store.dispatch('procedures/setState', [this.url, this.can, this.template])
    //this.$store.dispatch('procedures/fetch');

  },
  methods: {
    submitForm () {
      this.$store.dispatch('procedures/submitForm');
    },
    toggleEdit () {
      this.$store.dispatch('procedures/toggleEdit');
    },
  }
}
</script>

