<template>
  <div>
    <a href='#' @click.prevent.stop='isShown = true' :class='button_class'><i :class='icon'></i>{{button_text}}</a>
    <div class='hudu-modal__wrapper' v-if='isShown'>
      <div class='hudu_modal'>
        <div class='hudu_modal__header'>
          <h1>{{name}}</h1>
          <a href='#' class='close' @click.prevent.stop='isShown = false'><i class='fas fa-times'></i></a>
        </div>
        <div class='hudu_modal__content'>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
    
</template>

<script>

export default {
  props: ["name", "icon", "button_text", "button_class"],
  data: function () {
    return {
      isShown: false
    }
  },
  methods: {
    onClickOutside () {
      this.isShown = false
    }
  }
}
</script>

