<template>
  <section class='company-summary__section'>
    <header class='company-summary__section-header'>
      <h2>{{active == 0 ? "Active" : "All"}} Agreements</h2>
      <nav class='company-summary__agreements-nav'>
        <a href='#' @click.prevent.stop='active = 0' :class='{"selected": active == 0}'>Active</a>
        <a href='#' @click.prevent.stop='active = 1' :class='{"selected": active == 1}'>All</a>
      </nav>
    </header>
    <div class='company-summary__agreements company-summary__section-content--list'>
      <ul>
        <slot name='active' v-if='active == 0'></slot>
        <slot name='all' v-if='active == 1'></slot>
      </ul>
    </div>
  </section>
    
</template>

<script>

export default {
  props: [],
  data: function () {
    return {
     active: 0
    }
  },
  methods: {
  }
}
</script>

