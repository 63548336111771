
<template>
  <div class='groups__content__layouts__header'>
    <a v-if='isChecked == false' href='#' @click.prevent.stop='checkAll()'>Select All</a>
    <a v-if='isChecked == true' href='#' @click.prevent.stop='checkAll()'>Deselect All</a>
  </div>
</template>

<script>


export default {
  props: ["checkbox_class", "checkbox_name"],
  data () {
    return {
      isChecked: false
    }
  },
  mounted () {
    var checkboxes = document.querySelectorAll(`.${this.checkbox_class}`).length;
    var checkedCheckboxes = document.querySelectorAll(`.${this.checkbox_class}:checked`).length;
    
    if (checkboxes == checkedCheckboxes) {
      this.isChecked = true;
      console.log(checkboxes)
      console.log(checkedCheckboxes)
    }
  },
  methods: {
    checkAll () {
      var that = this;
      that.isChecked = !that.isChecked
      var checkboxes = document.getElementsByName(this.checkbox_name);
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = that.isChecked;
      }
    }
  },

}
</script>

