<template>
  <div>
    <div class='ipam__subnet-range'>
      <input type='hidden' name='network[address]' v-model='full_subnet_range'>

      <input autofocus placeholder='Enter range' type='text' @input='setMask' v-model='address' :disabled='disable_input'>
      <select v-model='mask' :disabled='disable_input'>
        <option v-for='option in options' :value='option.address'>{{option.address}}</option>
      </select>
    </div>
    <div class='ipam__subnet-range-helper'>
      <section>
        <h2>Number of Usable IP Addresses:</h2><p>{{selectedOption.addresses}}</p>
      </section>
      <section>
        <h2>Mask:</h2><p>{{selectedOption.subnet_mask}}</p>
      </section>
      <section>
        <h2>Mask bits:</h2><p>{{selectedOption.address.substring(1)}}</p>
      </section>
      <section>
        <h2>Wildcard:</h2><p>{{selectedOption.wildcard}}</p>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  props: ["preaddress", "disable_input"],
  data: function () {
    return {
      address: this.preaddress,
      mask: "/32",
      options: [
        {
          "address": "/1",
          "subnet_mask": "128.0.0.0",
          "addresses": 2147483646,
          "wildcard": "127.255.255.255"
        },
        {
          "address": "/2",
          "subnet_mask": "192.0.0.0",
          "addresses": 1073741822,
          "wildcard": "63.255.255.255"
        },
        {
          "address": "/3",
          "subnet_mask": "224.0.0.0",
          "addresses": 536870910,
          "wildcard": "31.255.255.255"
        },
        {
          "address": "/4",
          "subnet_mask": "240.0.0.0",
          "addresses": 268435454,
          "wildcard": "15.255.255.255"
        },
        {
          "address": "/5",
          "subnet_mask": "248.0.0.0",
          "addresses": 134217726,
          "wildcard": "7.255.255.255"
        },
        {
          "address": "/6",
          "subnet_mask": "252.0.0.0",
          "addresses": 67108862,
          "wildcard": "3.255.255.255"
        },
        {
          "address": "/7",
          "subnet_mask": "254.0.0.0",
          "addresses": 33554430,
          "wildcard": "1.255.255.255"
        },
        {
          "address": "/8",
          "subnet_mask": "255.0.0.0",
          "addresses": 16777214,
          "wildcard": "0.255.255.255"
        },
        {
          "address": "/9",
          "subnet_mask": "255.128.0.0",
          "addresses": 8388606,
          "wildcard": "0.127.255.255"
        },
        {
          "address": "/10",
          "subnet_mask": "255.192.0.0",
          "addresses": 4194302,
          "wildcard": "0.63.255.255"
        },
        {
          "address": "/11",
          "subnet_mask": "255.224.0.0",
          "addresses": 2097150,
          "wildcard": "0.31.255.255"
        },
        {
          "address": "/12",
          "subnet_mask": "255.240.0.0",
          "addresses": 1048574,
          "wildcard": "0.15.255.255"
        },
        {
          "address": "/13",
          "subnet_mask": "255.248.0.0",
          "addresses": 524286,
          "wildcard": "0.7.255.255"
        },
        {
          "address": "/14",
          "subnet_mask": "255.252.0.0",
          "addresses": 262142,
          "wildcard": "0.3.255.255"
        },
        {
          "address": "/15",
          "subnet_mask": "255.254.0.0",
          "addresses": 131070,
          "wildcard": "0.1.255.255"
        },
        {
          "address": "/16",
          "subnet_mask": "255.255.0.0",
          "addresses": 65534,
          "wildcard": "0.0.255.255"
        },
        {
          "address": "/17",
          "subnet_mask": "255.255.128.0",
          "addresses": 32766,
          "wildcard": "0.0.127.255"
        },
        {
          "address": "/18",
          "subnet_mask": "255.255.192.0",
          "addresses": 16382,
          "wildcard": "0.0.63.255"
        },
        {
          "address": "/19",
          "subnet_mask": "255.255.224.0",
          "addresses": 8190,
          "wildcard": "0.0.31.255"
        },
        {
          "address": "/20",
          "subnet_mask": "255.255.240.0",
          "addresses": 4094,
          "wildcard": "0.0.15.255"
        },
        {
          "address": "/21",
          "subnet_mask": "255.255.248.0",
          "addresses": 2046,
          "wildcard": "0.0.7.255"
        },
        {
          "address": "/22",
          "subnet_mask": "255.255.252.0",
          "addresses": 1022,
          "wildcard": "0.0.3.255"
        },
        {
          "address": "/23",
          "subnet_mask": "255.255.254.0",
          "addresses": 510,
          "wildcard": "0.0.1.255"
        },
        {
          "address": "/24",
          "subnet_mask": "255.255.255.0",
          "addresses": 254,
          "wildcard": "0.0.0.255"
        },
        {
          "address": "/25",
          "subnet_mask": "255.255.255.128",
          "addresses": 126,
          "wildcard": "0.0.0.127"
        },
        {
          "address": "/26",
          "subnet_mask": "255.255.255.192",
          "addresses": 62,
          "wildcard": "0.0.0.63"
        },
        {
          "address": "/27",
          "subnet_mask": "255.255.255.224",
          "addresses": 30,
          "wildcard": "0.0.0.31"
        },
        {
          "address": "/28",
          "subnet_mask": "255.255.255.240",
          "addresses": 14,
          "wildcard": "0.0.0.15"
        },
        {
          "address": "/29",
          "subnet_mask": "255.255.255.248",
          "addresses": 6,
          "wildcard": "0.0.0.7"
        },
        {
          "address": "/30",
          "subnet_mask": "255.255.255.252",
          "addresses": 2,
          "wildcard": "0.0.0.3"
        },
        {
          "address": "/31",
          "subnet_mask": "255.255.255.254",
          "addresses": "2*",
          "wildcard": "0.0.0.1"
        },
        {
          "address": "/32",
          "subnet_mask": "255.255.255.255",
          "addresses": 1,
          "wildcard": "0.0.0.0"
        }
          ]
        }
  },
  computed: {
    full_subnet_range () {
      return this.address + this.mask
    },
    selectedOption () {
      var that = this;
      return this.options.find(el => el.address === that.mask);
    }
  },
  methods: {
    setMask () {
      var str = this.address.split("/");
      if (str.length == 2 && str[str.length - 1]) {
        this.mask = "/" + str[str.length - 1];
        this.address = str[0]
      }
    }
  },
  mounted () {
    this.setMask()
  }
}
</script>

