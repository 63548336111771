
<template>
  <VDropdown :distance="5" :strategy="'fixed'">
    <!-- This will be the popover reference (for the events and position) -->
    <slot name='trigger' @click.prevent.stop></slot>

    <!-- This will be the content of the popover -->
    <template #popper>
      <slot name='content'></slot>
    </template>
  </VDropdown>
</template>

<script>
export default {
  props: [],
  data () {
    return {
      //show: false,
      right: false,
      hover: false,
      interactive: false
    }
  }
}
</script>

