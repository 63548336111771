<template>
  <div style='position: relative;' class='portal-quick-adder'>
    <div class='portal-quick-adder__show'>
      <a href='#' class='button button--success' @click.prevent.stop="isShown = true"><i class='fas fa-plus'></i>Quick Add</a>
    </div>
    <slot v-if='isShown'></slot>
  </div>
</template>

<script>
export default {
  props: [""],
  mounted () {
    
  },
  data () {
    return {
      isShown: false
    }
  }
}
</script>

