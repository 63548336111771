<template>
  <div class='restricter'>

    <p><b>Now</b>, matched to:  <a :href='newMatchUrl'>{{newMatch}}</a></p>
    <div class='search search--hitcher' v-click-outside="onClickOutside">
      <icon></icon>
      <input type='text'
        @click="showSearch = true"
        @input="onInput"
        @keydown.esc="showSearch = false"
        v-model='q' placeholder="Search for a new entity to match to...">
      <div class='search__box' v-show='showSearch'>
        <header class='search__header'>
          <label><input @change.prevent.stop="onInput" v-model='include_archived' type='checkbox'>Include archived</label>
        </header>
        <ul v-if='results.length > 0'>
          <li class='search__box__li__hitcher' v-for='(result,i) in results' :key="i">
            <h2>
              <a target="_blank" :href='result.url' class='button button--primary button--small'>View</a>
              <a href='#' @click.prevent="add(result.id)" class='button button--success button--small'>Match</a>
              <a href='#' @click.prevent="add(result.id)">
                {{result.name}}
                <span class='search__span'>{{ result.object_type }}</span>
              </a>
            </h2> 
          </li>
        </ul>
        <div class='empty' v-if='results.length == 0'>
          <p >No results found</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import Icon from "../components/icon.vue";
import vClickOutside from 'v-click-outside'

export default {
  props: ["objectType", "url", "objectId", "company"],
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside(evt) {
      this.showSearch = false;
      this.arrowCounter = -1;
    },
    add (id) {
      var that = this;
      that.$api.put(that.url, {
        company_id: id,
        matcher_id: that.objectId
      })
      .then(function (response) {
        that.newMatch = response.data.name;
        that.newMatchUrl = response.data.url;
        that.showSearch = false;
      }).catch(error => {
        alert(error.response.data)
      })
    },
    onInput () {

      var that = this;

      if (that.q.length > 0) {
        that.showSearch = true;
      }

      that.$api.get('/searches', {
        params: {
          q: that.q,
          type: that.objectType,
          include_archived: that.include_archived,
          company: that.company
        }
      })
      .then(function (response) {
        that.results = response.data;
      })
    }
  },
  data: function () {
    return {
      q: "",
      results: [],
      showSearch: false,
      newMatch: "",
      newMatchUrl: "",
      include_archived: false,
    }
  },
  components: {
    Icon
  }
}
</script>