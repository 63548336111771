<template>
  <div style='position: relative;'>
    <a href='#' class='button button--large button--secondary' @click.prevent.stop="isShown = true">Move</a>
    <div class='duplicator duplicator--mover' v-if='isShown'>
      <span><b>Move</b></span>
    
      <slot></slot>
      <a href='#' class='button button--secondary' @click.prevent.stop="isShown = false">Cancel</a>
      
    </div>
  </div>
</template>

<script>
export default {
  props: [""],
  mounted () {
    
  },
  data () {
    return {
      isShown: false
    }
  }
}
</script>

