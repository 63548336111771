<template>
  <div style='width: 100%'>
    <iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" allowfullscreen frameborder="0" :src='url'></iframe>
  </div>
</template>

<script>

export default {
  props: ["url"],
  data() {
    return {
      
    };
  }
}
</script>

<style scoped>

</style>
