<template>
  <p><a href='#' @click.prevent.stop='check'>Check Outbound Connection</a></p>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { mapGetters } from 'vuex'

export default {
  props: ["outbound_url"],
  data () {
    return {
      
    }
  },
  methods: {
    check () {
      var that = this;
      that.$api.post(that.outbound_url).then(function (response) {
        alert(response.data);
      }).catch(error => {
        console.log(error)
      })
    }
  },

}
</script>

