<template>
  <div class="release-notes">
    <div class="release-note" v-for="note in notes">
      <h2>{{note.published_date}}</h2>
      <article>
        <h3><span class="release-note__type">{{  note.release_type }}</span>Hudu Version {{note.name}} <span v-if='note.headline'> - {{note.headline}}</span></h3>
        <div v-html='note.notes'></div>
      </article>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  props: ["url"],
  data () {
    return {
      notes: []
    }
  },
  mounted () {
    this.grabNotes()
  },
  methods: {
    grabNotes () {
      var that = this;

      that.$api.get(that.url)
      .then(function (response) {
        that.notes = response.data;
      })
    }
  }
}
</script>
