<template>
  <div>
    <flash-message></flash-message>
    <a href='#' @click.prevent.stop='copy' class=''>
      <i class='fas fa-copy'></i><div id='companyAddressCopy'><slot></slot></div>
    </a>
  </div>
</template>

<script>
import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";

export default {
  props: ["value"],
  methods: {
    copy (e) {
      var that = this;
      that.setClipboard(document.querySelector("#companyAddressCopy").innerHTML, e);
    },
    setClipboard(value, e) {
      var that = this;
      ClipboardFunctions.copyText(value);

       that.flash('Copied Address!', 'success', 
        {
          timeout: 3000,
          important: true
        }
      );
    }
  }
}
</script>