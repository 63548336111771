<template>
  <div>
    <flash-message></flash-message>
    <a class='button button--secondary button--icon' v-tooltip="'Send Secure Note'" @click.prevent.stop='isShown = !isShown' href='#'><i class='far fa-paper-plane'></i></a>

    <Teleport to="body">
      <div class='manage_modal__wrapper' v-show='isShown'>
        <div class='manage_modal manage_modal--small'>
          <header>
            <h1><i class='fas fa-paper-plane icon'></i>Send Secure Note</h1>
            <a href='#' class='button button--secondary' @click.prevent.stop='isShown = false'>Cancel</a>
          </header>
          <main class='secure_notes'>
            <form @submit='submitForm' v-if='!saved'>
              <div class='form-group'>
                <label for='note'>Secure Note</label>
                <textarea id='note' required="true" v-model='note' ></textarea>
              </div>
              <div class='form-group'>
                <label for='label'>Optional Label</label>
                <input type='text' id='label' v-model='label'/>
              </div>
              <div class='form-group'>
                <label for='expiration_date'>Expires in</label>
                <select id='expiration_date' required=true v-model='expiration_date'>
                  <option v-for='(date, index) in dates' v-bind:value="date.value">{{date.text}}</option>
                </select>
              </div>
              <div class='form-group checkbox'>
                <input type='checkbox' id='first_view' v-model='first_view'>
                <label for='first_view'>Expire after 1st view</label>
              </div>
              <input type='submit' @click.prevent.stop='submitForm' class='button button--success' value='Encrypt and Create'>
            </form>
            <div class='secure_notes__success' v-if='saved'>
              <h1>Note Created!</h1>
              <p>You can copy this link to share this secure note with clients or end-users. This note has been encrypted with <b>Zero Knowledge Encryption</b> and this is the <b>last time</b> you can copy the link in Hudu.</p>
              <copier :url='lastNoteUrl'></copier>
              <a href='#' @click.prevent.stop='reset' class='button button--secondary'><i class='fas fa-redo'></i>Create a New Note</a>
            </div>
            <table class='secure_notes__list' v-if='notes.length > 0'>
              <tr>
                <th>Expires on</th>
                <th></th>
              </tr>
              <tr class='secure_note' v-for='(n, index) in notes'>
                <td>{{n.date}}</td>
                <td><div class='button-group'><a href='#' class='button button--danger button--small' @click.prevent.stop='deleteNote(n.id, index)'><i class='fas fa-trash'></i>Delete</a></div></td>
              </tr>
            </table>
          </main>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
const axios = require('axios');
var CryptoJS = require("crypto-js");
import flash from "../lib/flashes.js";
import Copier from "./copier.vue";
import Teleport from 'vue2-teleport';

export default {
  props: ["post_url", "company_id", "base_url", "dates"],
  components: {
    Copier,
    Teleport
  },
  data () {
    return {
      isShown: false,
      notes: [],
      note: "",
      expiration_date: this.dates[0].value,
      first_view: false,
      saved: false,
      encrypted_note: "",
      key: "",
      label: "",
      lastNoteUrl: ""
    }
  },
  mounted () {
    var that = this;
    that.generateKey()
    that.getNotes()
  },
  methods: {
    generateKey () {
      var that = this;
      that.key = this.generateId(40)
    },
    encrypt(message) {
      var that = this;
      var encrypted_message = CryptoJS.AES.encrypt(message, that.key);
      return encrypted_message
    },
    decrypt(encrypted_message) {
      var that = this;
      var decrypted_message = CryptoJS.AES.decrypt(encrypted_message, that.key);
    },
    dec2hex (dec) {
      return dec.toString(16).padStart(2, "0")
    },
    generateId (len) {
      var arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, this.dec2hex).join('')
    },
    getNotes () {
      var that = this;
      that.$api.get(`${that.post_url}?company_id=${that.company_id}`)
      .then(function (response) {
        that.notes = response.data.secure_notes;
      })
    },
    deleteNote(id, index) {
      var that = this;

      if(confirm('Are you sure?')) {
        that.$api.delete(`${that.base_url}/secure_notes/${id}`).then(function (response) {
          that.notes.splice(index, 1);
          flash('Deleted!','success')
        }).catch(error => {
          console.log(error)
        })
      }
    },
    reset () {
      var that = this;
      that.generateKey()
      that.note = ""
      that.expiration_date = this.dates[0].value
      that.first_view = false
      that.label = ""
      that.saved = false
      that.lastNoteUrl = ""
    },
    submitForm () {
      var that = this;

      var myString   = that.note;
      var myPassword = that.key;

      var encrypted_message = CryptoJS.AES.encrypt(myString, myPassword).toString()

      that.$api.post(that.post_url, {
        secure_note: {
          note: encrypted_message,
          expiration_date: that.expiration_date,
          first_view: that.first_view,
          company_id: that.company_id,
          label: that.label
        }
      })
        .then(function (response) {
          console.log(response)
          that.lastNoteUrl = `${that.base_url}/secure_notes/${response.data.id}?key=${that.key}`
          flash('Created!','success')
          that.saved = true
          that.getNotes()
        })
    }
  }
}
</script>

