<template>
    <div id='swagger'>
    </div>
</template>

<script>
    import SwaggerUI from 'swagger-ui'
    export default {
        props: ["json_url"],
        mounted() {
            SwaggerUI({
                dom_id: '#swagger',
                url: this.json_url,
            })
        },


    methods: {

    }
    }
</script>


