<template>
  <div>
   <vue-good-table
       mode="remote"
  @on-page-change="onPageChange"
  @on-sort-change="onSortChange"
  @on-column-filter="onColumnFilter"
  @on-per-page-change="onPerPageChange"
  :totalRows="totalRecords"
  :isLoading.sync="isLoading"
  :theme='dark_mode ? "nocturnal" : "default"'
  styleClass="vgt-table bordered striped condensed"
  :pagination-options="{
    enabled: true,
    mode: 'records',
    perPage: 50,
    position: 'bottom',
    perPageDropdown: [10, 20, 50, 100, 200],
    dropdownAllowAll: false,
    setCurrentPage: 1,
    nextLabel: 'next',
    prevLabel: 'prev',
    rowsPerPageLabel: 'Items per page',
    ofLabel: 'of',
    pageLabel: 'page', // for 'pages' mode
    allLabel: 'All'
  }"
  :rows="rows"
  :columns="columns">
    <template slot="table-row" slot-scope="props">
    <span v-if="props.column.field == 'record_name'">
      <a :href='props.row.record_url' v-if='!props.row.old_name'>{{props.row.record_name}}</a>
      <span v-if="props.row.old_name">{{props.row.old_name}}</span>
    </span>
    <span v-else-if="props.column.field == 'company_name'">
      <a :href='props.row.record_company_url'>{{props.row.company_name}}</a>
    </span>
    <span v-else-if="props.column.field == 'user_name'">
      <a :href='props.row.record_user_url'>
        {{props.row.user_name}}
        <i class="fal fa-browser" v-show='props.row.app_type == "app_browser_extension"'></i>
        <i class="fal fa-mobile" v-show='props.row.app_type == "app_mobile"'></i>
      </a>

    </span>
    <span v-else>
      {{props.formattedRow[props.column.field]}}
    </span>
  </template>
  </vue-good-table>
  </div>

</template>

<script>
import { VueGoodTable } from 'vue-good-table';

export default {
  props: ["url","options_for_actions", "options_for_users","dark_mode", "data_name"],
  components: {
    VueGoodTable
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems(true);
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
     this.serverParams.sort = {
        type: params[0].type,
        field: params[0].field
      };
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    // load items is what brings back the rows from server
    loadItems(didPageNumberChange) {
      var that = this;

      if (!didPageNumberChange) {
        this.updateParams({page: 1});
      }

      that.$api.get(that.url, {params: that.serverParams})
      .then(function(response) {
        that.totalRecords = response.data["meta"]["total_results"];
        that.rows = response.data["recordings"];
      }).catch(error => {
        alert(error.response.data)
      })

    }
  },
  data(){
    return {
      isLoading: false,
      columns: [
        {
          label: 'Record',
          field: 'record_name',
          html: true,
          width: '20%',
          sortable: false,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: false, // enable filter for this column
              placeholder: 'Filter Record', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
          },
        },
        {
          label: this.data_name,
          field: 'company_name',
          html: true,
          width: '15%',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: `Filter ${this.data_name}`, // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
          },
        },
        {
          label: 'Action',
          field: 'action',
          width: '20%',
          html: true,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Filter Action', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: this.options_for_actions, // dropdown (with selected values) instead of text input

          },
        },
        {
          label: 'User',
          field: 'user_name',
          html: true,
          width: '15%',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Filter User', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: this.options_for_users, // dropdown (with selected values) instead of text input
          },
        },
        {
          label: 'Date/Time',
          field: 'formatted_datetime',
          html: true,
          width: '15%',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: false, // enable filter for this column
              placeholder: 'Filter Date/Time', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
          },
        },
        {
          label: 'IP Address',
          field: 'ip_address',
          html: true,
          width: '20%',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Filter IP Address', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: [] , // dropdown (with selected values) instead of text input
          },
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 50
      }
    };
  },
}
</script>

