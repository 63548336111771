
<template>
  <div class='groups__content__layouts__header'>
    <a v-if='isChecked == false' href='#' @click.prevent.stop='checkAll()'>Select All</a>
    <a v-if='isChecked == true' href='#' @click.prevent.stop='checkAll()'>Deselect All</a>
  </div>
</template>

<script>


export default {
  props: [],
  data () {
    return {
      isChecked: false
    }
  },
  mounted () {
    var checkboxes = document.querySelectorAll('.denied_asset_layout_id_checkbox').length;
    var checkedCheckboxes = document.querySelectorAll('.denied_asset_layout_id_checkbox:checked').length;
    
    if (checkboxes == checkedCheckboxes) {
      this.isChecked = true;
      console.log(checkboxes)
      console.log(checkedCheckboxes)
    }
  },
  watch: {
    foo() {
      console.log('Foo Changed!');
    }
  },
  methods: {
    checkAll () {
      var that = this;
      that.isChecked = !that.isChecked
      var checkboxes = document.getElementsByName('denied_asset_layout_ids[]');
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = that.isChecked;
      }
    }
  },

}
</script>

