<template>
  <div>
    <flash-message></flash-message>

    <h1 v-if='!isEdit'>


      <div class='breadcrumb breadcrumb--content'>
        <ul>
          <li v-for='(i, index) in items'>
            <template v-if='index != items.length - 1'>
              <a :href='i.item.url' v-if='newName != i.item.name'>{{i.item.name}}</a>
            </template>
            <template v-if='index == items.length - 1'>
              <li>
                <a v-tooltip.auto="'Change Name'" href='#' @click.prevent.stop='toggleEdit' class='name__edit' v-if='can'>{{newName}}</i></a>
                <template v-if='!can'>{{newName}}</template>
              </li>
            </template>
          </li>
        </ul>
      </div>
    </h1>
    <div v-if='isEdit' class='name__edit-form'>
      <form @submit.prevent.stop='save'>
        <input type='text' id='newNameInput' v-model='newName' placeholder="Enter a new name" required="true" v-on:keyup.enter="save" v-click-outside="save">
      </form>
    </div>
  </div>
</template>

<script>
import flash from "../lib/flashes.js";
import vClickOutside from 'v-click-outside';

export default {
  props: ["can", "url", "items"],
  data() {
    return {
     isEdit: false,
     newName: this.items[this.items.length - 1].item.name
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    toggleEdit () {
      this.isEdit = !this.isEdit;
      this.$nextTick(() => document.querySelector("#newNameInput").focus())
    },
    save () {
      var that = this;
      if (that.newName == that.name) {
        that.isEdit = false
        return
      }
      that.$api.put(that.url, { name: that.newName}).then(function (response) {
        that.isEdit = false
        flash('Name Saved!','success')
      }).catch(error => {
      })
    }
  }
};
</script>

<style scoped>

</style>
