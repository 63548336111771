<template>
  <div class='dark-moder__wrapper'>
    <a class='dark-moder' href='#' v-if='darkMode' @click.prevent.stop="toggle"><i class='fas fa-sun'></i> Enable Light Mode</a>
    <a class='dark-moder' href='#' v-if='!darkMode' @click.prevent.stop="toggle"><i class='fas fa-moon'></i> Enable Dark Mode</a>
  </div>
</template>

<script>
export default {
  props: ["url"],
  data () {
    return {
      darkMode: false
    }        
  },
  mounted () {
    if (document.body.classList.contains("dark-portal")) {
      this.darkMode = true;
    }
  },
  methods: {
    toggle () {

      var that = this;

      that.$api.post(that.url).then(function () {
        if (!that.darkMode) {
          that.darkMode = true;
          document.body.classList.add("dark-portal");
        } else {
          that.darkMode = false;
          document.body.classList.remove("dark-portal");
        }
      })
    }
  }
}
</script>

