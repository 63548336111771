<template>


  <div class='password'>
    <flash-message></flash-message>
    <a href='#' v-if="!isShown && !compact" class='button button--secondary' @click.prevent.stop='getPassword()'><i class='fas fa-eye'></i>{{reveal_name}}</a>
    <transition name='fade'>
      <div class='inputer' style='width: 100%' v-if="isShown && !compact">
        <input type='text' readonly v-model='password'>
        <aside>
          <a href='#' v-if='!isCopied' @click.prevent.stop="copy($event)"><i class='fas fa-copy'></i></a>
          <a href='#' v-if='isCopied' @click.prevent.stop="copy($event)">Copied!</a>
        </aside>
      </div>
    </transition>
    <div class='password__options' v-if='!compact && !isShown'>
      <input type='text' readonly value='' style='display: none;'>
      <a href='#' v-if='!isCopied' @click.prevent.stop="copy($event)" class='button button--secondary'><i class='fas fa-copy'></i>{{copy_name}}</a>
      <a href='#' v-if='isCopied' @click.prevent.stop class='button button--secondary'>{{copied_name}}</a>
    </div>
    <div v-if='compact'>
      <input type='text' readonly value='' style='display: none;'>
      <a href='#' v-if='!isCopied' @click.prevent.stop="copy($event)" class='button button--small button--secondary'><i class='fas fa-copy'></i>Password</a>
      <a href='#' v-if='isCopied' @click.prevent.stop class='button button--small button--secondary'>{{copied_name}}</a>
    </div>
    <transition name='fade'>
      <div v-if='isShown && isEasy' class='password__easyreadbox'>
        <div class='password__lovely' v-html='lovely'>
        </div>
      </div>
    </transition>
    <transition name='fade'>

    </transition>
    <div v-if='isShown && !isEasy' class='password__info'>
      <div class='button-group'>
        <a href='#' class='button button--small button--secondary' @click.prevent.stop="isEasy = true"><i class="fas fa-binoculars"></i>{{easy_read_name}}</a>
      </div>
    </div>
  </div>

</template>

<script>

import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";

export default {
  name: "password-viewer",
  props: ["url", "compact", "preeasy_read_name", "prereveal_name", "precopy_name", "precopied_name"],
  computed: {
    lovely () {
      var that = this;
      let result = '';
      let h =  {
        "A": "ALPHA",  "B": "BRAVO",   "C": "CHARLIE",
        "D": "DELTA",  "E": "ECHO",    "F": "FOXTROT",
        "G": "GOLF",   "H": "HOTEL",   "I": "INDIA",
        "J": "JULIETT","K": "KILO",    "L": "LIMA",
        "M": "MIKE",   "N": "NOVEMBER","O": "OSCAR",
        "P": "PAPA",   "Q": "QUEBEC",  "R": "ROMEO",
        "S": "SIERRA", "T": "TANGO",   "U": "UNIFORM",
        "V": "VICTOR", "W": "WHISKEY", "X": "X-RAY",
        "Y": "YANKEE", "Z": "ZULU", "0": "Zero",
        "a": "alpha",  "b": "bravo",   "c": "charlie",
        "d": "delta",  "e": "echo",    "f": "foxtrot",
        "g": "golf",   "h": "hotel",   "i": "india",
        "j": "juliett","k": "kilo",    "l": "lima",
        "m": "mike",   "n": "november","o": "oscar",
        "p": "papa",   "q": "quebec",  "r": "romeo",
        "s": "sierra", "t": "tango",   "u": "Uniform",
        "v": "victor", "w": "whiskey", "x": "x-ray",
        "y": "yankee", "z": "zulu", "0": "Zero",
        "1": "One", "2": "Two", "3": "Three",
        "4": "Four", "5": "Five", "6": "Six",
        "7": "Seven", "8": "Eight", "9": "Nine"
      }
      for (let character of that.password) {
        if (/^\d+$/.test(character)) {
          // it is a number
          result += `<div class='lovely-item lovely--number'><span>${character}</span><p>${h[character]}</p></div>`;
        } else if (character.match(/[A-Z|a-z]/i)){
          // it is a letter
          result += `<div class='lovely-item'><span>${character}</span><p>${h[character]}</p></div>`;
        } else {
          // it is a special character or something weeeeeiiiiirrdddd.
          result += `<div class='lovely-item lovely--special'><span>${character}</span><p>&nbsp;</p></div>`;
        }
      }
      return result;
    }
  },
  methods: {
    getPassword () {
      var that = this;
      fetch(that.url)
      .then((resp) => resp.json())
      .then(function(data) {
        that.isShown = true;
        that.password = data.password;

        that.flash("Revealed!", 'success',
          {
            timeout: 3000,
            important: true
          }
        );
      })

    },
    copy (e) {

      var that = this;


      if(that.password == "") {
        var xhttp = new XMLHttpRequest();
        xhttp.open("GET", that.url, false);
        xhttp.send();
        var response = JSON.parse(xhttp.responseText);
        that.setClipboard(response["password"], e)
      } else {
        that.setClipboard(that.password, e)
      }
      // if (that.password == "") {
      //   fetch(that.url)
      //   .then((resp) => resp.json())
      //   .then(function(data) {
      //     that.password = data.password;
      //     that.isShown = true;
      //     that.setClipboard(data.password, e)
      //   })
      // } else {
      //   that.setClipboard(that.password, e)
      // }
    },
    setClipboard(value, e) {

      var that = this;

      ClipboardFunctions.copyText(value)

      that.isCopied = true;

      that.flash("Copied!", 'success',
        {
          timeout: 3000,
          important: true
        }
      );

      setTimeout(function(){ that.isCopied = false; }, 2000);
    }
  },
  data: function () {
    return {
      isShown: false,
      isEasy: false,
      isCopied: false,
      password: "",
      easy_read_name: this.preeasy_read_name || "Easy Read",
      reveal_name: this.prereveal_name || "Reveal",
      copy_name: this.precopy_name || "Copy",
      copied_name: this.precopied_name || "Copied!"
    }
  }
}
</script>
