<template>
  <div>
    <div id='map'></div>
  </div>
</template>

<script>

import mapboxgl from '!mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import vClickOutside from 'v-click-outside'
import { mapGetters } from 'vuex'

export default {
  props: ["access_token", "mapbox_style", "lat", "lon"],
  data () {
    return {
      isShown: false
    }
  },
  mounted () {
    var that = this;
    mapboxgl.accessToken = 'pk.eyJ1IjoiamFjb2JodWR1IiwiYSI6ImNrcHJhemJlZTAybXEyb28xdDllZWx1eWIifQ.2GHjyOql58454LhzIqsRxQ';
    var map = new mapboxgl.Map({
    container: 'map',
    style: this.mapbox_style,
    center: [that.lon, that.lat],
    zoom: 13,
    attributionControl: false
    });
     
    // Create a default Marker and add it to the map.
    var marker1 = new mapboxgl.Marker()
    .setLngLat([that.lon, that.lat])
    .addTo(map);

    const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl
    });
  },
  methods: {
    
  },

}
</script>
