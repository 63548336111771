<template>
  <div>
    <div class='inputer' v-if='!small'>
      <flash-message></flash-message>
      <input type='text' :value='url' readonly>
      <aside>
        <a href='#' v-if='!isCopied' @click.prevent.stop="copy"><i class='fas fa-copy'></i> {{text}}</a>
        <a href='#' v-if='isCopied' @click.prevent.stop="copy">Copied!</a>
      </aside>
    </div>
    <div v-if='small'>
      <a href='#' v-if='!isCopied' @click.prevent.stop="copy" class='button button--secondary button--small'><i class='fas fa-copy'></i>Username</a>
      <a href='#' v-if='isCopied' @click.prevent.stop="copy"  class='button button--secondary button--small'>Copied!</a>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  props: ["url", "text", "small"],
  data: function () {
    return {
      isCopied: false
    }
  },
  methods: {
    copy (e) {

      var that = this;
      that.setClipboard(that.url, e)
    },
    setClipboard(value, e) {
      var that = this;

      Clipboard.copy(value);
      that.isCopied = true;


      setTimeout(function(){ that.isCopied = false; }, 2000);

      that.flash("Copied!", 'success',
        {
          timeout: 3000,
          important: true
        }
      );

      // navigator.permissions.query({
      //   name: 'clipboard-write'
      // }).then(permissionStatus => {
      //   console.log(permissionStatus.state);

      //   permissionStatus.onchange = () => {
      //     console.log(permissionStatus.state);
      //   };
      // });

      // navigator.clipboard.writeText(value)
      // .then(() => {
      //   that.isCopied = true;
      // })
      // .catch(err => {
      //   console.log("error copying")
      // });
    }
  },
}


window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard() {
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        createTextArea(text);
        selectText();
        copyToClipboard();
    };

    return {
        copy: copy
    };
})(window, document, navigator);
</script>

