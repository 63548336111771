<template>
  <div>
   <vue-good-table
      ref='iptable'
       mode="remote"
  @on-page-change="onPageChange"
  @on-sort-change="onSortChange"
  @on-column-filter="onColumnFilter"
  @on-per-page-change="onPerPageChange"
  @on-selected-rows-change="selectionChanged"
  :totalRows="totalRecords"
  :isLoading.sync="isLoading"
  :theme='dark_mode ? "nocturnal" : "default"'
  styleClass="vgt-table striped"
  :select-options="{
    enabled: true,
    selectOnCheckboxOnly: true,
    selectionInfoClass: 'custom-select-row',
  }"
  :pagination-options="{
    enabled: true,
    mode: 'records',
    perPage: 50,
    position: 'bottom',
    perPageDropdown: [10, 20, 50, 100, 200],
    dropdownAllowAll: false,
    setCurrentPage: 1,
    nextLabel: 'next',
    prevLabel: 'prev',
    rowsPerPageLabel: 'Items per page',
    ofLabel: 'of',
    pageLabel: 'page', // for 'pages' mode
    allLabel: 'All'
  }"
  :rows="rows"
  :columns="columns">
    <div slot="selected-row-actions" v-if='can'>
      <div class='button-group'>
        <!-- <a href='#' @click.prevent.stop='deleteRecords' class='button button--secondary'>Edit Selected</a> -->
        <a href='#' @click.prevent.stop='deleteRecords' class='button button--danger'>Delete Selected</a>
      </div>
    </div>
    <template slot="table-row" slot-scope="props">
    <span v-if="props.column.field == 'address'">
      <a :href='props.row.url'>{{props.row.address}}</a>
    </span>
    <span v-if="props.column.field == 'assigned_to'">
      <a :href='props.row.asset_url'>{{props.row.asset_name}}</a>
    </span>
    <span v-if="props.column.field != 'address' && props.column.field != 'assigned_to'">
      {{props.formattedRow[props.column.field]}}
    </span>
  </template>
  </vue-good-table>
  </div>

</template>

<script>
import { VueGoodTable } from 'vue-good-table';

export default {
  props: ["dark_mode", 'url', 'options_for_status', 'can', 'bulk_delete_url'],
  components: {
    VueGoodTable
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
     this.serverParams.sort = {
        type: params[0].type,
        field: params[0].field
      };
      this.loadItems();
    },
    selectionChanged(event) {
      // this.selectedRows = event.selectedRows.map(a => a.id)
    },
    deleteRecords() {
      var that = this;

      var id_list = that.$refs["iptable"].selectedRows.map(a => a.id)

      if (id_list.length == 0) {
        return;
      }

      that.$api.delete(that.bulk_delete_url, {data: {id_list: id_list}})
      .then(function(response) {
        location.reload();
      }).catch(error => {
        //alert(error.response)
      })
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    // load items is what brings back the rows from server
    loadItems() {
      var that = this;
      that.$api.get(that.url, {params: that.serverParams})
      .then(function(response) {
        that.totalRecords = response.data["meta"]["total_results"];
        that.rows = response.data["ip_addresses"];
      }).catch(error => {
        //alert(error.response)
      })

    }
  },
  data(){
    return {
      isLoading: false,
      columns: [
        {
          label: 'IP Address',
          field: 'address',
          html: true,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Filter IP Addresses', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
          },
        },
        {
          label: 'Status',
          field: 'status',
          html: true,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Filter Status', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: this.options_for_status // dropdown (with selected values) instead of text input
          },
        },
        {
          label: 'Assigned To',
          field: 'assigned_to',
          html: true,
          sortable: false,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: false, // enable filter for this column
              placeholder: 'Filter Assigned To', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input

          },
        },
        {
          label: 'FQDN',
          field: 'fqdn',
          html: true,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Filter FQDN', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
          },
        },
      ],
      rows: [],
      totalRecords: 0,
      //selectedRows: [],
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 50
      }
    };
  },
}
</script>

