<template>
  <div class='dashboard__item-pictures'>
    <div @click.prevent.stop='changeSelected(0)' :class='{"dashboard__item-picture dashboard__item-picture--0": true, "dashboard__item-picture--selected": selected == 0}'><h1 class='dashboard__item-pictures--blank'>None</h1></div>
    <div @click.prevent.stop='changeSelected(1)' :class='{"dashboard__item-picture dashboard__item-picture--1": true, "dashboard__item-picture--selected": selected == 1}'></div>
    <div @click.prevent.stop='changeSelected(2)' :class='{"dashboard__item-picture dashboard__item-picture--2": true, "dashboard__item-picture--selected": selected == 2}'></div>
    <div @click.prevent.stop='changeSelected(3)' :class='{"dashboard__item-picture dashboard__item-picture--3": true, "dashboard__item-picture--selected": selected == 3}'></div>
    <div @click.prevent.stop='changeSelected(4)' :class='{"dashboard__item-picture dashboard__item-picture--4": true, "dashboard__item-picture--selected": selected == 4}'></div>
    <div @click.prevent.stop='changeSelected(5)' :class='{"dashboard__item-picture dashboard__item-picture--5": true, "dashboard__item-picture--selected": selected == 5}'></div>
    <div @click.prevent.stop='changeSelected(6)' :class='{"dashboard__item-picture dashboard__item-picture--6": true, "dashboard__item-picture--selected": selected == 6}'></div>
    <div @click.prevent.stop='changeSelected(7)' :class='{"dashboard__item-picture dashboard__item-picture--7": true, "dashboard__item-picture--selected": selected == 7}'></div>
    <div @click.prevent.stop='changeSelected(8)' :class='{"dashboard__item-picture dashboard__item-picture--8": true, "dashboard__item-picture--selected": selected == 8}'></div>
    <div @click.prevent.stop='changeSelected(9)' :class='{"dashboard__item-picture dashboard__item-picture--9": true, "dashboard__item-picture--selected": selected == 9}'></div>
    <div @click.prevent.stop='changeSelected(10)' :class='{"dashboard__item-picture dashboard__item-picture--10": true, "dashboard__item-picture--selected": selected == 10}'></div>
    <div @click.prevent.stop='changeSelected(11)' :class='{"dashboard__item-picture dashboard__item-picture--11": true, "dashboard__item-picture--selected": selected == 11}'></div>
    <div @click.prevent.stop='changeSelected(12)' :class='{"dashboard__item-picture dashboard__item-picture--12": true, "dashboard__item-picture--selected": selected == 12}'></div>
    <div @click.prevent.stop='changeSelected(14)' :class='{"dashboard__item-picture dashboard__item-picture--14": true, "dashboard__item-picture--selected": selected == 14}'></div>
    <div @click.prevent.stop='changeSelected(15)' :class='{"dashboard__item-picture dashboard__item-picture--15": true, "dashboard__item-picture--selected": selected == 15}'></div>
  </div>
</template>

<script>


export default {
  props: ["url", "pre"],
  data () {
    return {
      selected: this.pre
    }
  },
  methods: {
    changeSelected(number) {
      var that = this;
      that.$api.post(that.url, {"dashboard_image": number})
      .then(function (response) {
        that.selected = number
      }).catch(error => {
        alert("Something went wrong...")
      })
    }
  },

}
</script>

