<template>
  <div>
    <h2>{{date}}</h2>
    <h1>{{time}}<span>{{time_span}}</span></h1>
  </div>
</template>

<script>

var dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

export default {
  props: ["user_timezone", "military_time"],
  data () {
    return {
      time: "",
      time_span: "",
      date: ""
    }
  },
  mounted () {
    var that = this;
    that.getDateAndTime()
    setInterval(function(){that.getDateAndTime()}, 1000);
  },
  methods: {
    getDateAndTime () {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      var now = dayjs().tz(this.user_timezone)
      if (this.military_time) {
        this.time = now.format('H:mm')
      } else {
        this.time = now.format('h:mm')
        this.time_span = now.format('A')
      }
      
      this.date = now.format('MMMM D, YYYY')
    }
  }

}
</script>

