<template>
  <div class='modal-backdrop' v-if='isShown'>
    <div class='modal'>
      <div class='timer'>
        <slot></slot>
        Your session will be timed out in <b style='color: red'>{{timer_seconds}}</b> seconds
        <a href='#' @click.prevent.stop='stopCountdown' class='button button--secondary'>Cancel Timeout</a>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  props: ["seconds", "dashboard_url"],
  mounted () {

    var that = this;
    
    that.isShown = false;
    setTimeout(function(){
      that.isShown = true;
      that.startCountdown()
    }, that.seconds * 1000);
  },
  data: function () {
    return {
      timer_seconds: 30,
      isShown: true,
      downloadTimer: false
    }
  },
  methods: {
    stopCountdown () {
      var that = this;
      clearInterval(that.downloadTimer);
      that.timer_seconds = 30,
      that.isShown = false;

      fetch(that.dashboard_url)
      .then(function(data) {
        // Do nothing
      })

      setTimeout(function(){
        that.isShown = true;
        that.startCountdown()
      }, that.seconds * 1000);

    },
    startCountdown () {
      var that = this;

      that.downloadTimer = setInterval(function(){
        if(that.timer_seconds <= 0){
          document.querySelector("#deleteSessionButton").click()
          return clearInterval(that.downloadTimer);
        }
        that.timer_seconds -= 1;
      }, 1000);
    }
  }
}
</script>

