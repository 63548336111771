<template>
  <div style='position: relative; display: inline-block;'>
    <a v-if='updateAvailable' @click.prevent.stop="isShown = !isShown" data-tooltip="Update is available!" class="tooltip-right header__a header__a--update"><i class="fas fa-exclamation-circle"></i></a>

    <div class='updater' v-if='isShown'>
      <header>
        <h2>🥳{{friendly}} is here!</h2><a href=''></a><a href='#' class='button button--secondary' @click.prevent.stop="isShown = false">Close</a>
      </header>
      <footer>
        <a :href='blog' target="_blank" v-if='blog' class='button button--primary'>More Info</a>
        <slot></slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: ["magic", "major", "minor", "patch", "can"],
  mounted () {
    var that = this;
    fetch("https://www.usehudu.com/updates.json")
    .then((resp) => resp.json())
    .then(function(data) {
      that.newMajor = data["latest"]["major"]
      that.newMinor = data["latest"]["minor"]
      that.newPatch = data["latest"]["patch"]
      that.friendly = data["latest"]["friendly_name"]
      that.blog = data["latest"]["blog"]

      that.calculate()
    })
  },
  data () {
    return {
      isShown: false,
      newMajor: 0,
      newMinor: 0,
      blog: "",
      friendly: "",
      newPatch: 0,
      updateAvailable: false
    }
  },
  methods: {
    calculate () {
      var that = this;
      if (that.newMajor <= that.major) {
        if (that.newMinor <= that.newMinor) {
          if (that.newPatch <= that.patch) {
            that.updateAvailable = false
          } else {
            that.updateAvailable = true;
          }
        } else {
          that.updateAvailable = true;
        }
      } else {
        that.updateAvailable = true;
      }
    }
  }
}
</script>

