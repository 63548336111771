<template>
  <div class='subnet'>
    <section>
      <div class='info-panel'>
        <div class='info-panel__header'>
          <h2>Details</h2>
        </div>
        <div class='info-panel__item'>
          <h3>Rack Utilization</h3>
          <section class='subnet__utilization-section'>
            <span>{{formatted_utilization}}</span>
            <div class='subnet__utilization'>
              <div class='subnet__utilization--track' v-if='rack_storage.utilization <= 100' :style='{"width": formatted_utilization}'></div>
              <div class='subnet__utilization--track subnet__utilization--track--red' v-if='rack_storage.utilization > 100' style='width: 100%'></div>
            </div>
          </section>
        </div>
        <div class='info-panel__item'>
          <h3>Power Utilization</h3>
          <section class='subnet__utilization-section'>
            <span>{{formatted_power_utilization}}</span>
            <div class='subnet__utilization'>
              <div class='subnet__utilization--track' v-if='rack_storage.power_utilization <= 100' :style='{"width": formatted_power_utilization}'></div>
              <div class='subnet__utilization--track subnet__utilization--track--red' v-if='rack_storage.power_utilization > 100' style='width: 100%'></div>
            </div>
          </section>
        </div>
        <div class='info-panel__item'>
          <h3>Power Draw</h3>
          <section class='subnet__utilization-section'>
            <span>{{formatted_power_draw_utilization}}</span>
            <div class='subnet__utilization'>
              <div class='subnet__utilization--track' v-if='rack_storage.power_draw_utilization <= 100' :style='{"width": formatted_power_draw_utilization}'></div>
              <div class='subnet__utilization--track subnet__utilization--track--red' v-if='rack_storage.power_draw_utilization > 100' style='width: 100%'></div>
            </div>
          </section>
        </div>
        <a class='info-panel__expand' href='#' @click.prevent.stop='isInfoExpanded = true' v-if='!isInfoExpanded'>
          <h3><i class='fas fa-chevron-down'></i>Expand Info</h3>
        </a>
        <div class='info-panel__expanded' v-if='isInfoExpanded'>
          <div class='info-panel__item' v-if='rack_storage.location_name'>
            <h3>Location</h3>
            <section>
              <p><a :href='rack_storage.location_url'>{{rack_storage.location_name}}</a></p>
            </section>
          </div>
          <div class='info-panel__item' v-if='rack_storage.width'>
            <h3>Width</h3>
            <section>
              <p>{{rack_storage.width}} {{name_of_length_or_width_measurement}}</p>
            </section>
          </div>
          <div class='info-panel__item' v-if='rack_storage.height'>
            <h3>Height</h3>
            <section>
              <p><b>{{rack_storage.height}}U</b> ({{rack_storage.descending_units ? "Descending" : "Ascending" }})</p>
            </section>
          </div>
          <div class='info-panel__item'>
            <h3>Starting Unit</h3>
            <section>
              <p>{{rack_storage.starting_unit}}</p>
            </section>
          </div>
          <div class='info-panel__item' v-if='rack_storage.asset_tag'>
            <h3>Asset Tag</h3>
            <section>
              <p>{{rack_storage.asset_tag}}</p>
            </section>
          </div>
          <div class='info-panel__item' v-if='rack_storage.serial_number'>
            <h3>Serial Number</h3>
            <section>
              <p>{{rack_storage.serial_number}}</p>
            </section>
          </div>
          <div class='info-panel__item' v-if='rack_storage.max_wattage'>
            <h3>Max wattage</h3>
            <section>
              <p>{{rack_storage.max_wattage}} watts</p>
            </section>
          </div>
          <div class='info-panel__item' v-if='rack_storage.description'>
            <h3>Description</h3>
            <section>
              <p>{{rack_storage.description}}</p>
            </section>
          </div>
        </div>
      </div>
    </section>
    <section>
      <!-- <div class="toggle-button rack-storages__toggle-button">
        <a href="#" :class="{'selected': format == 1}" @click.prevent.stop="format = 1">Split View</a>
        <a href="#" :class="{'selected': format == 2}" @click.prevent.stop="format = 2">Single Column</a>
      </div> -->
      <div :class='{"rack-storages": true, "rack-storages--single": format == 2}'>
        <div class='rack-storage'>
          <div class='rack-storage__header'>
            <h2>Front</h2>
          </div>
          <div :class='{"rack-storage__reversed": rack_storage.descending_units}'>
            <div class='rack-storage__row' v-for="item in rack_storage.front_items" :key="item.number">
              <div class='rack-storage__label'>
                <span>{{item.number}}</span>
              </div>
              <div class='rack-storage__units'>
                <a :href='i.asset_url' :class="createClassForRackUnit(i.rack_storage_role_hex_color)" v-for='(i,index) in item.items' v-if='item.has_items' :key='index'>
                  <div class='rack-storage__text rack-storage__text--stacked'>
                    <h3>{{i.asset_name}}</h3>
                    <p v-if='i.rack_storage_role_name'>{{i.rack_storage_role_name}}</p>
                  </div>
                  <div class='rack-storage__text' v-if='item.is_reserved'>Reserved <span v-if='item.reserved_message'>for {{item.reserved_message}}</span></div>
                  <div class='rack-storage__actions'>
                    <a href='#' v-if='can' @click.prevent.stop='edit(i.url)' class='rack-storage__trigger'><i class="fas fa-pencil"></i></a>
                  </div>
                </a>
                <div class='rack-storage__unit rack-storage__unit--reserved' v-if='item.is_reserved'>
                  <div class='rack-storage__text'>Reserved <span v-if='item.reserved_message'>for {{item.reserved_message}}</span></div>
                  <div class='rack-storage__actions'>
                    <a href='#' v-if='can' @click.prevent.stop='edit(item.url)' class='rack-storage__trigger'><i class="fas fa-pencil"></i></a>
                  </div>
                </div>
                <div class='rack-storage__unit' v-if='!item.has_items && !item.is_reserved'>
                  <div class='rack-storage__text'>No Device</div>
                </div>
              </div>
              <div class='rack-storage__actions' v-if='!item.is_reserved'>
                <a href='#' v-if='can' @click.prevent.stop='add(item.number, "front")' class='rack-storage__trigger'><i class="fas fa-add"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class='rack-storage'>
          <div class="rack-storage__header">
            <h2>Rear</h2>
          </div>
          <div :class='{"rack-storage__reversed": rack_storage.descending_units}'>
            <div class='rack-storage__row' v-for="item in rack_storage.rear_items" :key="item.number">
              <div class='rack-storage__label'>
                <span>{{item.number}}</span>
              </div>
              <div class='rack-storage__units'>
                <a :href='i.asset_url' :class="createClassForRackUnit(i.rack_storage_role_hex_color)" v-for='(i,index) in item.items' v-if='item.has_items' :key='index'>
                  <div class='rack-storage__text rack-storage__text--stacked'>
                    <h3>{{i.asset_name}}</h3>
                    <p v-if='i.rack_storage_role_name'>{{i.rack_storage_role_name}}</p>
                  </div>
                  <div class='rack-storage__text' v-if='item.is_reserved'>Reserved <span v-if='item.reserved_message'>for {{item.reserved_message}}</span></div>
                  <div class='rack-storage__actions'>
                    <a href='#' v-if='can' @click.prevent.stop='edit(i.url)' class='rack-storage__trigger'><i class="fas fa-pencil"></i></a>
                  </div>
                </a>
                <div class='rack-storage__unit rack-storage__unit--reserved' v-if='item.is_reserved'>
                  <div class='rack-storage__text'>Reserved <span v-if='item.reserved_message'>for {{item.reserved_message}}</span></div>
                  <div class='rack-storage__actions'>
                    <a href='#' v-if='can' @click.prevent.stop='edit(item.url)' class='rack-storage__trigger'><i class="fas fa-pencil"></i></a>
                  </div>
                </div>
                <div class='rack-storage__unit' v-if='!item.has_items && !item.is_reserved'>
                  <div class='rack-storage__text'>No Device</div>
                </div>
              </div>
              <div class='rack-storage__actions' v-if='!item.is_reserved'>
                <a href='#' v-if='can' @click.prevent.stop='add(item.number, "rear")' class='rack-storage__trigger'><i class="fas fa-add"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Teleport to="body">
      <div class='manage_modal__wrapper' v-show='isShown'>
        <div class='manage_modal manage_modal--small'>
          <header>
            <h1>{{isEdit ? "Edit Item" : "Add Item"}}</h1>
            <a href='#' class='button button--secondary' @click.prevent.stop='cancelForm'>Cancel</a>
          </header>
          <div class="manage__modal__body">
            <form @submit.prevent.stop="addOrEditItem" class='form'>
              <ul class='alert--errors' v-if="error">
                <li><i class="fas fa-exclamation-triangle"></i>{{error}}</li>
              </ul>
              <div class='rack__storage__reference-form form-group'>
                <div class='rack__storage__reference-input'>
                  <label for='start_unit'>Start:</label>
                  <input id='start_unit' type='number'  v-model='rack_storage_item.start_unit'>
                </div>
                <div class='rack__storage__reference-input'>
                  <label for='end_unit'>End:</label>
                  <input id='end_unit' type='number' v-model='rack_storage_item.end_unit'>
                </div>
              </div>
              <div class='form-group'>
                <label>Rack side</label>
                <label class='checkbox'>
                  <input type='radio' v-model='rack_storage_item.side' value='front'>Front
                </label>
                <label class='checkbox'>
                  <input type='radio' v-model='rack_storage_item.side' value='rear'>Rear
                </label>
                <label class='checkbox'>
                  <input type='radio' v-model='rack_storage_item.side' value='both'>Full Depth
                </label>
              </div>
              <div class='form-group'>
                <label>Mark as...</label>
                <label class='checkbox'>
                  <input type='radio' v-model='rack_storage_item.status' value='reserved'>Reserved
                </label>
                <label class='checkbox'>
                  <input type='radio' v-model='rack_storage_item.status' value='used'>Used by device
                </label>
              </div>
              <div class='form-group' v-if='rack_storage_item.status == "reserved"'>
                <label for='reserved_message'>Reserved for...</label>
                <input id='reserved_message' type='text' v-model='rack_storage_item.reserved_message'>
              </div>
              <div class='form-group' v-if='rack_storage_item.status == "used"'>
                <label for='asset_id'>Device</label>
                <input id='asset_id' type='hidden' v-model='rack_storage_item.asset_id'>
                <asset-picker @added="deviceAdded" @removed='deviceRemoved' :existing="{name: rack_storage_item.asset_name, id: rack_storage_item.asset_id}" :company_id='company_id'></asset-picker>
              </div>
              <div class='form-group' v-if='rack_storage_item.status == "used" && roles.length > 0'>
                <label for='role'>Role</label>
                <select id='role' v-model='rack_storage_item.rack_storage_role_id'>
                  <option value=''>Choose a role</option>
                  <option v-for='role in roles' :value='role.id'>{{role.name}}</option>
                </select>
              </div>
              <div class='form-group-half' v-if='rack_storage_item.status == "used"'>
                <div class='form-group'>
                  <label for='power_draw'>Power draw</label>
                  <input type='number' id='power_draw' v-model='rack_storage_item.power_draw'>
                </div>
                <div class='form-group'>
                  <label for='max_wattage'>Max wattage</label>
                  <input type='number' id='max_wattage' v-model='rack_storage_item.max_wattage'>
                </div>
              </div>
              <div class='actions'>
                <div class='button-group'>

                  <a v-if='isEdit' @click.prevent.stop='removeItem' href='#' class='button button--danger'>Remove Item From Rack</a>
                  <input type='submit' class='button button--primary' :value='isEdit ? "Update" : "Create"'>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
const axios = require('axios');
import DropdownComponent from "./DropdownComponent.vue";
import Teleport from 'vue2-teleport';
import flash from "../lib/flashes.js";
import AssetPicker from './AssetPicker.vue'

export default {
  props: ["url", "rack_storage_items_url", "roles", "company_id", "can", "json", "name_of_length_or_width_measurement"],
  mounted () {
    var that = this;
    that.getRackStorage()
  },
  components: {
    DropdownComponent,
    Teleport,
    AssetPicker,
  },
  computed: {
    formatted_power_draw_utilization () {
      return this.rack_storage.power_draw_utilization + "%";
    },
    formatted_power_utilization () {
      return this.rack_storage.power_utilization  + "%";
    },
    formatted_utilization () {
      return this.rack_storage.utilization + "%";
    }
  },
  methods: {
    add(number, side) {
      this.isShown = true;
      this.isEdit = false;
      this.rack_storage_item.side = side
      this.rack_storage_item.start_unit = number;
      this.rack_storage_item.end_unit = number;
    },
    edit(url) {
      this.isShown = true;
      this.isEdit = true;
      var that = this;

      that.$api.get(url)
      .then(function(response) {
        that.rack_storage_item = response.data.rack_storage_item
      }).catch(error => {
        console.log(error)
        alert("Something went wrong!")
      })
    },
    createClassForRackUnit(rack_storage_role_hex_color) {
      if (rack_storage_role_hex_color && rack_storage_role_hex_color != "yellow") {
        return "rack-storage__unit rack-storage__unit--colored rack-storage__unit--" + rack_storage_role_hex_color;
      } else if (rack_storage_role_hex_color == "yellow") {
        return "rack-storage__unit rack-storage__unit--yellow";
      } else {
        return "rack-storage__unit";
      }
    },
    deviceAdded (device_id) {
      this.rack_storage_item.asset_id = device_id
    },
    deviceRemoved (device_id) {
      this.rack_storage_item.asset_id
    },
    selectColor (color) {
      this.selectedColor = color;
    },
    cancelForm () {
      this.isShown = false;
      this.clearEdit()
      this.isEdit = false;
    },
    clearEdit () {
      this.rack_storage_item = {
        side: "front",
        start_unit: 1,
        end_unit: 1,
        asset_id: "",
        rack_storage_role_id: "",
        status: "reserved",
        reserved_message: "",
        power_draw: "",
        max_wattage: "",
      }
    },
    removeItem () {
      var that = this;
      if(confirm('Are you sure?')) {
        that.$api.delete(that.rack_storage_item.url)
        .then(function(response) {
          that.getRackStorage()
          that.clearEdit()
          that.isShown = false;
          flash('Item Removed!','success')
        }).catch(error => {
          console.log(error)
          alert("Something went wrong!")
        })
      }
    },
    addOrEditItem () {
      var that = this;

      if (this.isEdit == true) {
        that.$api.put(that.rack_storage_item.url, that.rack_storage_item).then(function (response) {
          that.getRackStorage()
          that.clearEdit()
          that.isShown = false;
          flash('Item Changed!','success')
        }).catch(error => {
          flash(error.response.data[0],'error')
        })
      } else {
        that.$api.post(`${that.rack_storage_items_url}`, that.rack_storage_item).then(function (response) {
          that.getRackStorage()
          that.clearEdit()
          that.isShown = false;
          flash('Item Changed!','success')
        }).catch(error => {
          flash(error.response.data[0],'error')
        })
      }
    },
    getRackStorage () {
      var that = this;
      if (this.json) {
        that.rack_storage = this.json;
        return;
      }
      that.$api.get(that.url)
      .then(function(response) {
        that.rack_storage = response.data.rack_storage
      }).catch(error => {
        alert("Something went wrong!")
      })
    },
  },
  data: function () {
    return {
      isEdit: true,
      isInfoExpanded: false,
      isShown: false,
      error: "",
      format: 1,
      selectedDevice: "",
      options: [
        { name: 'Vue.js', id: 'JavaScript' },
        { name: 'Rails', id: 'Ruby' },
        { name: 'Sinatra', id: 'Ruby' },
        { name: 'Laravel', id: 'PHP' },
        { name: 'Phoenix', id: 'Elixir' }
      ],
      rack_storage: {
        utilization: 0,
        power_draw_utilization: 0,
        power_utilization: 0
      },
      rack_storage_item: {
        side: "front",
        start_unit: 1,
        end_unit: 1,
        asset_id: "",
        rack_storage_role_id: "",
        status: "reserved",
        reserved_message: "",
        power_draw: "",
        max_wattage: "",
        side: "front"
      }
    }
  },
}
</script>

