<template>
  <div class='relatives' :class="{'relatives--active': add}">
    <flash-message></flash-message>
    <a class='relative--new'  v-show='!add && can' href='#' @click.prevent.stop="startAdd">
      <i class='fas fa-plus'></i>Relate
    </a>
    <a href='#' v-show='add' @click.prevent.stop="add = false" class='relative--new relative--cancel'>
      <i class='fas fa-times'></i>Close
    </a>
    <div class='relative' v-for="(relation, index) in relations">

      <template v-if='editIndex != index'>
        <a :href='relation.url' class='clicker'>
          <i :class='relation.icon'></i>
          <article>
            <h2>{{relation.name}}</h2>
            <p v-show='relation.description'>{{relation.description}}</p>
          </article>
        </a>
      </template>
      <template v-if='isEdit && (index === editIndex)'>
        <form @submit.prevent.stop='updateDescription(relation.id)'>
          <div>
            <h2>{{relation.name}}</h2>
            <input type='text' v-model='newDescription' :id='"relativerInput" + index' placeholder='Describe relation...'>
          </div>
          <div>
            <a href='#' @click.prevent.stop='cancelEdit' class='button button--secondary button--small'>Cancel</a>
            <a href='#' @click.prevent.stop='updateDescription(relation.id)' class='button button--primary button--small'>Save</a>
            <a href='#' v-if='can' class='button button--danger button--small' @click.prevent.stop="removeRelation(relation.id, index)">
              Delete
            </a>
          </div>
        </form>
      </template>
      <a href='#' v-if='can && !isEdit' class='relative__edit' @click.prevent.stop="editRelation(index, relation.description)">
        <i class='fas fa-edit'></i>
      </a>
    </div>
    <div class='relatives__toolbar' v-show='add'>
      <select @change.prevent.stop="onInput" v-model='isType'>
        <option value='Company'>{{structure_name}}</option>
        <option>Asset</option>
        <option>Article</option>
        <option>Password</option>
        <option>Website</option>
      </select>
      <label v-if='company_id && (isType != "Company")'><input @change.prevent.stop="onInput" v-model='only_company' type='checkbox'>Limit to&nbsp;<b>{{company_name}}</b></label>
          <label><input @change.prevent.stop="onInput" v-model='include_archived' type='checkbox'>Include archived</label>
    </div>
    <div class='relatives__add' v-show='add'>
      <div class='search search--hitcher' v-click-outside="onClickOutside">
        <icon></icon>
        <input ref='searchInput' type='text'
          @click="showSearch = true"
          @input="onInput"
          @keydown.esc="showSearch = false"
          v-model='q' placeholder="Search to add...">
      </div>
    </div>
    <div class='relatives__results' v-show='add'>
      <div class='relatives__result' v-for='result in results'>
        <div class='button-group'>
          <a href='#' class='button button--small button--success' @click.prevent.stop="addRelation(result.id, result.object_type)">Relate</a>
          <a :href='result.url' target="_blank" class='button button--small button--secondary'><i style='margin: 0;' class='fas fa-external-link-alt'></i></a>
        </div>
        {{result.name}}<small v-if='result.asset_type'>{{result.asset_type}} / {{result.company_name}}</small>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import vClickOutside from 'v-click-outside'
import Icon from "../components/icon.vue";

export default {
  name: 'relater',
  props: ["id", "type", "can", "company_name", "company_id", "default", "structure_name"],
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted () {

    var that = this;

    that.$api.get(`/relations/get_relations?id=${that.id}&type=${that.type}`)
    .then(function(response) {
      console.log(response.data);
      that.relations = response.data
    }).catch(error => {
      alert(error.response.data)
    })
  },
  methods: {
    startAdd () {
      this.add = true;
      this.resetEdit()
    },
    addRelation (id,type) {
      var that = this;
      that.$api.post('/relations', {
        relation: {
          fromable_type: that.type,
          fromable_id: that.id,
          toable_type: type,
          toable_id: id
        }
      })
      .then(function (response) {
        that.relations.unshift(response.data)
        that.showSearch = false;
        that.flash("Relation Added!", 'success', 
          {
            timeout: 3000,
            important: true
          }
        );
      }).catch(error => {
        alert(error.response.data)
      })
    },
    cancelEdit() {
      this.resetEdit()
    },
    resetEdit () {
      this.isEdit = false;
      this.editIndex = -1;
      this.newDescription = ""
    },
    editRelation(index, description) {
      this.isEdit = true;
      this.editIndex = index;
      this.newDescription = description;
      this.$nextTick(() => document.querySelector("#relativerInput" + index).focus())
    },
    updateDescription (id) {
      var that = this;
      that.$api.put(`/relations/${id}`, {relation: {description: that.newDescription}}).then(function (response) {
        that.resetEdit()
        that.$api.get(`/relations/get_relations?id=${that.id}&type=${that.type}`)
        .then(function(response) {
          console.log(response.data);
          that.relations = response.data
        }).catch(error => {
          alert(error.response.data)
        })
        that.flash("Relation Updated!", 'success', 
          {
            timeout: 3000,
            important: true
          }
        );
      }).catch(error => {
        console.log(error)
      })
    },
    removeRelation (id, index) {
      var that = this;
      if(confirm('Are you sure you want to delete this relation?')) {
        that.$api.delete(`/relations/${id}`).then(function (response) {
          that.relations.splice(index, 1);
          that.resetEdit()
          that.flash("Relation Removed!", 'success', 
            {
              timeout: 3000,
              important: true
            }
          );
        }).catch(error => {
          console.log(error)
        })
      }
    },
    onClickOutside(evt) {
      this.showSearch = false;
      this.arrowCounter = -1;
    },
    onInput () {

      var that = this;

      axios.get('/searches', {
        params: {
          q: that.q,
          type: that.isType,
          company: that.only_company ? that.company_id : "",
          include_archived: that.include_archived,
          disable_type: that.type,
          disable_id: that.id
        }
      })
      .then(function (response) {
        console.log(response.data)
        that.results = response.data;
      })
    }
  },
  watch: {
    add (newAdd, oldAdd) {
      if (newAdd == true)
        this.$nextTick(() => this.$refs['searchInput'].focus())
    }
  },
  data: function () {
    return {
      relations: [],
      q: "",
      isType: this.default,
      results: [],
      add: false,
      only_company: true,
      showSearch: false,
      newDescription: "",
      include_archived: false,
      isEdit: false,
      editIndex: -1
    }
  },
  components: {
    Icon
  }
}
</script>