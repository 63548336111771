import Vue from "vue/dist/vue.esm";

export default function flash(message, flashType) {
  return Vue.prototype.$flashStorage.flash(message, flashType,
    {
      timeout: 3000,
      important: true
    }
  );
}
